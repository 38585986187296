/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useState, useEffect } from 'react';
import { retry } from 'ts-retry-promise';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import moment from '../../utils/moment';
import { sendSentryError } from '../../utils/sentry';
import {
  MolliePaymentInfoDocument,
  MolliePaymentInfoQuery,
} from '../../apollo';
import { Spinner } from '../../styled';
import { Title } from './Title';
import { Text } from './Text';
import { Icon } from './Icon';

const PaymentSubscription = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const client = useApolloClient();

  useEffect(() => {
    let mounted = true;
    let retryCount = 0;

    retry(
      async () => {
        if (!mounted) return;

        retryCount++;

        const queryStartTime = moment.utc().format();

        let retryReportObject: Record<string, unknown> = {
          retryCount,
          queryStartTime,
        };

        const { error, data } = await client.query<MolliePaymentInfoQuery>({
          query: MolliePaymentInfoDocument,
          fetchPolicy: 'network-only',
        });

        if (!mounted) return;

        const queryEndTime = moment.utc().format();

        retryReportObject = {
          ...retryReportObject,
          queryEndTime,
        };

        if (error) {
          retryReportObject = {
            ...retryReportObject,
            queryError: error,
            errorMessage: 'Error on query; throwing error for retry',
          };

          console.warn('payment subscription retry report', retryReportObject);

          throw new Error('No data in query response');
        }

        const { status, createdAt } = data.getMolliePaymentInfo;

        retryReportObject = {
          ...retryReportObject,
          queryError: error,
          queryData: data.getMolliePaymentInfo,
        };

        const recordsDifference = moment
          .utc()
          .diff(moment.utc(createdAt), 'minute');

        const freshRecord = recordsDifference < 1;

        if (!(status === 'valid' && freshRecord)) {
          console.warn('payment subscription retry report', {
            ...retryReportObject,
            errorMessage: `getMolliePaymentInfo record is not freshly updated or invalid; throwing error for retry`,
          });

          throw new Error(
            'getMolliePaymentInfo record is not freshly updated or invalid'
          );
        }

        console.warn('payment subscription retry report', {
          ...retryReportObject,
          message: `All is fine, showing success content`,
        });

        setError(false);
      },
      {
        delay: 1000,
        retries: 15,
      }
    )
      .catch(sendSentryError)
      .finally(() => {
        mounted && setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [client]);

  if (loading) {
    return (
      <>
        <Title>
          {t('NOTIFICATION_PAYMENT_SUBSCRIPTION_MODAL__loadingTitle')}
        </Title>
        <Spinner
          style={{
            marginTop: 20,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Icon type={'sadFace'} />
        <Title>
          {t('NOTIFICATION_PAYMENT_SUBSCRIPTION_MODAL__errorTitle')}
        </Title>
        <Text>{t('NOTIFICATION_PAYMENT_SUBSCRIPTION_MODAL__errorDesc')}</Text>
      </>
    );
  }

  return (
    <>
      <Icon type={'success'} />
      <Title>
        {t('NOTIFICATION_PAYMENT_SUBSCRIPTION_MODAL__successTitle')}
      </Title>
      <Text>{t('NOTIFICATION_PAYMENT_SUBSCRIPTION_MODAL__successDesc')}</Text>
    </>
  );
};

export { PaymentSubscription };
