/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { css } from 'styled-components';
import { Breakpoint } from '@mui/material';
import { theme } from './theme';
import { ColorName } from '../utils/colors';

type UtilFn = (
  breakPoint: Breakpoint | number
) => (arg0: { theme: typeof theme }) => string;

export const getDownMedia: UtilFn =
  (brPoint) =>
  ({ theme }) =>
    theme.breakpoints.down(brPoint);

export const getUpMedia: UtilFn =
  (brPoint) =>
  ({ theme }) =>
    theme.breakpoints.up(brPoint);

export const pxToRem = (px: number) => css`
  ${({ theme }) => theme.typography.pxToRem(px)};
`;

export const getColor = (color: ColorName) => css`
  ${({ theme }) => theme.palette.colors[color]}
`;

export const getSpacing = (value: number) => css`
  ${({ theme }) => theme.spacing(value)}
`;

export const visuallyHidden = css`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
export const resetListStyles = css`
  padding: 0;
  margin: 0;
  list-style: none;
`;

// noinspection CssInvalidPropertyValue
export const fullScreenHeightStyles = css`
  min-height: 100vh;

  @supports (background: -webkit-named-image(i)) {
    min-height: -webkit-fill-available;
    overflow-y: scroll; /* has to be scroll, not auto */
    -webkit-overflow-scrolling: touch;
  }
`;
