/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { createTheme, ThemeOptions } from '@mui/material';
import { colors } from '../utils/colors';
import {
  fontSize,
  regularTextFontFamily,
  titleTextFontFamily,
} from '../utils/fonts';

const mainColor = colors.blue;
const secondaryColor = colors.veniceBlue;

const defBreakpoints: ThemeOptions['breakpoints'] = {
  values: {
    xs: 0,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

const defTypography: ThemeOptions['typography'] = {
  htmlFontSize: fontSize,
};

const {
  breakpoints,
  spacing,
  typography: { pxToRem },
} = createTheme({ breakpoints: defBreakpoints, typography: defTypography });

export const theme = createTheme({
  breakpoints: defBreakpoints,
  typography: {
    htmlFontSize: fontSize,
    fontFamily: regularTextFontFamily,
    h1: {
      fontSize: pxToRem(26),
      lineHeight: 1.32,
      fontFamily: titleTextFontFamily,
    },
    h2: {
      fontSize: pxToRem(24),
      lineHeight: 1.32,
      fontWeight: 600,
      fontFamily: titleTextFontFamily,
    },
    h3: {
      fontSize: pxToRem(18),
      lineHeight: 1.2,
      fontWeight: 600,
      fontFamily: titleTextFontFamily,
      [breakpoints.down('sm')]: {
        fontSize: pxToRem(20),
      },
    },
    subtitle1: {
      // fontSize: '1.1428571428571428rem', // default
      // lineHeight: 1.75,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: pxToRem(16),
      lineHeight: 1.5,
      fontWeight: 600,
    },
    body1: {
      fontSize: pxToRem(16),
      [breakpoints.down('sm')]: {
        fontSize: pxToRem(17),
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle2: 'p',
        },
      },
      styleOverrides: {
        root: {
          color: colors.darkBlue,
          '& .MuiLink-root': {
            fontWeight: 400,
          },
        },
        h1: {
          color: secondaryColor,
          '&.MuiTypography-gutterBottom': {
            marginBottom: spacing(4.25),
            [breakpoints.down('sm')]: {
              marginBottom: spacing(2.5),
            },
          },
        },
        h2: {
          color: secondaryColor,
          '&.MuiTypography-gutterBottom': {
            marginBottom: spacing(2.5),
            [breakpoints.down('sm')]: {
              marginBottom: spacing(1.5),
            },
          },
        },
        h3: {
          color: secondaryColor,
          '&.MuiTypography-gutterBottom': {
            marginBottom: spacing(2),
            [breakpoints.down('sm')]: {
              marginBottom: spacing(1.25),
            },
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontFamily: regularTextFontFamily,
          cursor: 'pointer',
          '&:hover': {
            color: secondaryColor,
            textDecoration: 'none',
          },
        },
        underlineAlways: {
          '&:hover': {
            textDecoration: 'none',
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: { color: 'primary' },
      styleOverrides: {
        root: {
          width: 26,
          height: 26,
          padding: 4,
          marginLeft: -4,
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: { variant: 'contained', fullWidth: true },
      styleOverrides: {
        root: {
          fontSize: pxToRem(17),
          lineHeight: 1.2,
          fontWeight: 600,
          textTransform: 'none',
        },
        sizeSmall: {
          // font-size: 0.9285714285714286rem; default
          // padding: 4px 10px; default
        },
        contained: {
          padding: '15px 24px 17px',
          borderRadius: 3,
          color: colors.white,
          '&.MuiButton-containedError': {
            backgroundColor: colors.flamingo,
            '&:hover': {
              backgroundColor: secondaryColor,
              boxShadow: 'none',
              '@media (hover: none)': {
                backgroundColor: secondaryColor,
                boxShadow: 'none',
              },
            },
          },
          [breakpoints.down('sm')]: {
            padding: '17px 24px 19px',
            lineHeight: 1.2615,
          },
        },
        containedSizeSmall: {
          padding: '10px 10px',
          fontSize: '1rem', // 14px
        },
        containedPrimary: {
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: secondaryColor,
            boxShadow: 'none',
            color: colors.white,
            '@media (hover: none)': {
              backgroundColor: secondaryColor,
              boxShadow: 'none',
              color: colors.white,
            },
          },
        },
        outlined: {
          padding: '15px 15px',
          borderRadius: 3,
        },
        outlinedPrimary: {
          borderColor: colors.blue2,
          color: mainColor,
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: secondaryColor,
            color: secondaryColor,
            '@media (hover: none)': {
              backgroundColor: 'transparent',
              color: secondaryColor,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 38,
          height: 38,
        },
        sizeSmall: {
          width: 20,
          height: 20,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        square: true,
      },
      styleOverrides: {
        rounded: {
          borderRadius: 3,
        },
        elevation1: {
          boxShadow: '0 0 11px 0 rgba(63, 73, 105, 0.05)',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(10, 53, 90, 0.7)',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: 3,
            'overflow-y': 'visible',
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: 12,
          marginBottom: 8,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: colors.darkBlue,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            fontWeight: 600,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          color: colors.boulder,
          // transform: 'translate(14px, 18px) scale(1)',
          /*'&$shrink': {
            transform: 'translate(14px, -6px) scale(1)',
          },*/
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: 'normal',
        variant: 'outlined',
        fullWidth: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          paddingTop: 16.5,
        },
        notchedOutline: {
          borderRadius: 3,
        },
      },
    },
    MuiRadio: {
      defaultProps: { color: 'primary' },
    },
    MuiSelect: {
      defaultProps: { variant: 'outlined' },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: '#fff',
          border: `1px solid ${colors.gray}`,
          color: colors.black,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: colors.darkBlue,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          color: colors.darkBlue,
        },
      },
    },
  },
  palette: {
    colors,
    primary: {
      main: mainColor,
    },
    secondary: {
      main: colors.blue2,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 'auto',
      height: 50,
      [breakpoints.down('md')]: {
        height: 60,
      },
    },
  },
});
