/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLogout, useSignIn } from '../../hooks';
import { useAlert } from '../../providers';
import { useGoogleSignInMutation } from '../../apollo';
import { sendSentryError } from '../../utils/sentry';
import { isServerErrorOfType } from '../../utils/server-helpers';
import { serverResponseErrorsCodes } from '../../utils/consts';
import { AuthLayout } from '../../layouts';
import { LoginForm as DefLoginForm } from '../../components/forms';
import { AuthPageTitle } from '../../components/texts';
import { AuthPageChangeBlock } from '../../components/AuthPageChangeBlock';
import { AuthLogo } from '../../components/AuthLogo';
import { GoogleSigninButton } from '../../components/buttons';
import { AuthSplitBlock } from '../../components/AuthSplitBlock';

const LoginPage = () => {
  useLogout();
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const signInHandler = useSignIn();

  const [signIn] = useGoogleSignInMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ googleSignIn }) => {
      signInHandler({
        data: googleSignIn,
      });
    },
    onError: (error) => {
      const googleUserNotFoundError = isServerErrorOfType({
        error,
        type: serverResponseErrorsCodes.GOOGLE_USER_NOT_FOUND,
      });

      if (googleUserNotFoundError) {
        setAlert({
          type: 'warning',
          message: t('LOGIN_PAGE__googleAccountNotExistError'),
        });
        return;
      }

      setAlert({
        type: 'error',
        message: t('GOOGLE_AUTH_BUTTON__error'),
      });

      sendSentryError(error);
    },
  });

  return (
    <Wrapper>
      <AuthLogo />
      <AuthPageTitle>{t('LOGIN_PAGE__title')}</AuthPageTitle>
      <GoogleSigninButton
        text={t('GOOGLE_AUTH_BUTTON__signin')}
        onSuccess={(tokenId) => {
          signIn({
            variables: {
              tokenId,
            },
          });
        }}
      />
      <AuthSplitBlock />
      <LoginForm />
      <AuthPageChangeBlock />
    </Wrapper>
  );
};

const Wrapper = styled(AuthLayout)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-bottom: 20px;
  }
`;

const LoginForm = styled(DefLoginForm)``;

export { LoginPage };
