/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

const DollarIcon = (props: IconProps) => (
  <Icon viewBox="0 0 22 22" {...props}>
    <circle cx={11} cy={11} r={11} fillRule="evenodd" />
    <path
      d="M10.816 18.66a.748.748 0 00.552-.216.748.748 0 00.216-.552v-1.296c.885-.16 1.613-.517 2.184-1.072.57-.555.856-1.232.856-2.032 0-.928-.25-1.63-.752-2.104-.501-.475-1.264-.845-2.288-1.112V6.724c.341.075.667.192.976.352.31.16.56.336.752.528a.533.533 0 00.368.128.696.696 0 00.52-.224.717.717 0 00.216-.512c0-.245-.123-.475-.368-.688a3.74 3.74 0 00-1.072-.704 5.265 5.265 0 00-1.392-.4v-.848a.763.763 0 00-.208-.552.702.702 0 00-.528-.216.775.775 0 00-.56.216.733.733 0 00-.224.552v.832c-.95.117-1.715.45-2.296 1-.581.55-.872 1.245-.872 2.088 0 .875.272 1.565.816 2.072.544.507 1.328.877 2.352 1.112v3.696a3.934 3.934 0 01-1.184-.368 4.488 4.488 0 01-1.056-.752.662.662 0 00-.464-.176.71.71 0 00-.528.232.731.731 0 00-.224.52c0 .245.112.464.336.656.459.427.939.747 1.44.96a5.466 5.466 0 001.68.4v1.264c0 .224.072.408.216.552a.728.728 0 00.536.216zm-.752-8.768c-.533-.17-.941-.387-1.224-.648-.283-.261-.424-.605-.424-1.032 0-.395.147-.73.44-1.008.293-.277.696-.459 1.208-.544v3.232zm1.52 5.184V11.86c.49.181.861.408 1.112.68.25.272.381.61.392 1.016 0 .363-.139.677-.416.944-.277.267-.64.459-1.088.576z"
      fill="#FFF"
      fillRule="nonzero"
    />
  </Icon>
);

export { DollarIcon };
