/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { AppPageContentWrapper as DefAppPageContentWrapper } from '../../styled';
import {
  getDownMedia,
  getUpMedia,
  desktopContentSidePaddings,
  mobileContentSidePaddings,
} from '../../styles';

const ContentBlock = styled(DefAppPageContentWrapper)<{
  $sameVerticalPaddings?: boolean;
}>`
  padding-bottom: ${({ $sameVerticalPaddings }) =>
    $sameVerticalPaddings ? 37 : 62}px;

  & + & {
    margin-top: ${desktopContentSidePaddings}px;
  }

  ${getUpMedia('lg')} {
    padding-right: 34%;
  }

  ${getDownMedia('md')} {
    & + & {
      margin-top: ${mobileContentSidePaddings}px;
    }
  }

  ${getDownMedia('sm')} {
    padding-bottom: 52px;
  }
`;

export { ContentBlock };
