/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { Text, TextProps } from '../texts';
import { commonBottomBlocksTextStyles } from '../../styles/dashboard-page';
import { getUpMedia } from '../../styles';

export const BottomBlockTitle = (props: TextProps) => (
  <Title variant={'h3'} {...props} />
);

const Title = styled(Text)`
  margin-bottom: 8px;
  ${commonBottomBlocksTextStyles};

  ${getUpMedia('sm')} {
    font-size: 1.21428571rem;
  }
`;
