/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Title } from './Title';
import { Text as DefText } from './Text';
import { Icon } from './Icon';

const RemoveAccountLinkExpired = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon type={'error'} />
      <Title>
        {t('NOTIFICATION_REMOVE_ACCOUNT_LINK_EXPIRED_MODAL__title')}
      </Title>
      <Text>{t('NOTIFICATION_REMOVE_ACCOUNT_LINK_EXPIRED_MODAL__desc')}</Text>
    </>
  );
};

const Text = styled(DefText)`
  margin-left: 10px;
  margin-right: 10px;
`;

export { RemoveAccountLinkExpired };
