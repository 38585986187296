/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { useField } from 'formik';
import { CountriesSelect, CountriesSelectProps } from '../form-elements';

export type FormikCountriesSelectProps = Omit<
  CountriesSelectProps,
  'onChange'
> & {
  name: string;
};

const FormikCountriesSelect = ({
  name,
  ...props
}: FormikCountriesSelectProps) => {
  const [field, { touched, error }] = useField(name);

  return (
    <CountriesSelect
      {...field}
      {...props}
      {...(touched && !!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikCountriesSelect };
