/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  isNumber,
  /*, toNumber*/
} from 'lodash-es';
import { useTranslation } from 'react-i18next';
import {
  ChangeCell,
  LockButtonCell,
  NameCell,
  OrderCell,
  PercentageCell,
  PriceCell,
  TableRow,
  TokenAmountCell,
  ValueCell,
} from 'styled/portfolio';
import {
  /*Tooltip,*/
  TableRowProps,
} from '@mui/material';
// import { sendSentryError } from '../../utils/sentry';
import { externalLinkProps } from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { getPercentages } from '../../utils/percentages';
import {
  /*useAlertModal,*/
  useTruncateText,
  useIsMobile,
} from '../../hooks';
import {
  PortfolioCurrency, // useLockCurrencyMutation,
  // UserLockedCurrenciesDocument,
  PortfolioRowResponse,
} from '../../apollo';
import { getColor } from '../../styles';
import {
  /*useAlert,*/
  usePortfolioCurrency,
} from '../../providers';
import { useDesktopSmall } from '../../hooks/useDesktopSmall';
// import { Button } from '../buttons';
// import { QuestionMarkInCircleIcon } from '../icons';
import { Text } from '../texts';

export type PortfolioTableRowProps = {
  data: PortfolioTableRowData;
  index: number;
  hoveredPiePieceName: string;
} & TableRowProps;

const PortfolioTableRow = ({
  data,
  index,
  hoveredPiePieceName,
  ...props
}: PortfolioTableRowProps) => {
  const { t } = useTranslation();
  // const { setAlert } = useAlert();
  const truncateText = useTruncateText();
  const mobile = useIsMobile();
  const isDesktopSmall = useDesktopSmall();
  // const { dispatch: dispatchAlertModal } = useAlertModal();
  const { currency } = usePortfolioCurrency();

  const { price, changePercent, value, nameText } = useMemo(() => {
    const price =
      currency === PortfolioCurrency.Eur
        ? getPrice(data.eurValue, undefined, false)
        : getPrice(
            data.usdValue,
            {
              currency: 'USD',
            },
            false
          );

    const changePercent = getPercentages(
      currency === PortfolioCurrency.Eur
        ? data.change24Percentage
        : data.usdChange24Percentage,
      {
        withSign: true,
      }
    );

    const value =
      currency === PortfolioCurrency.Eur
        ? getPrice(data.cost)
        : getPrice(data.usdCost, {
            currency: 'USD',
          });

    const nameText =
      truncateText(data.title) +
      (!isDesktopSmall ? ` | ${data.symbol.toUpperCase()}` : '');

    return {
      price,
      changePercent,
      value,
      nameText,
    };
  }, [
    currency,
    data.change24Percentage,
    data.cost,
    data.eurValue,
    data.symbol,
    data.title,
    data.usdChange24Percentage,
    data.usdCost,
    data.usdValue,
    truncateText,
    isDesktopSmall,
  ]);

  /*const [lockCurrency, { loading: lockingCurrency }] = useLockCurrencyMutation({
    refetchQueries: [
      {
        query: UserLockedCurrenciesDocument,
      },
    ],
    awaitRefetchQueries: true,
  });*/

  return (
    <TableRow
      hover={!mobile}
      $lockedFiat={data.isFiat && data.locked}
      $hover={data.title === hoveredPiePieceName}
      {...props}
    >
      <OrderCell>{data.isFiat ? '' : `${index + 1}.`}</OrderCell>
      <NameCell>
        <div>
          <IconImg src={data.icon} />
          {data.isFiat ? (
            <NameCellText>
              <>
                {nameText}
                {data.locked && (
                  <span>{t('DASHBOARD_PAGE__portfolioLockedHelperText')}</span>
                )}
              </>
            </NameCellText>
          ) : (
            <NameCellLink href={data.messari} {...externalLinkProps}>
              {nameText}
            </NameCellLink>
          )}
        </div>
      </NameCell>
      {data.isFiat ? (
        <LockButtonCell colSpan={2}>
          {/* Temp comment out before everything is implemented*/}
          {/*<div>
            <LockButton
              size={'small'}
              $locked={data.locked}
              loading={lockingCurrency}
              onClick={async () => {
                try {
                  await lockCurrency({
                    variables: {
                      id: toNumber(data.id),
                      status: !data.locked,
                    },
                  });
                  dispatchAlertModal([
                    'setModalContent',
                    !data.locked ? 'lockedCurrency' : 'unlockedCurrency',
                  ]);
                } catch (e) {
                  sendSentryError(e);
                  setAlert({
                    message: t('DASHBOARD_PAGE__lockUnlockErrorAlertMessage'),
                    type: 'error',
                  });
                }
              }}
            >
              {data.locked
                ? t('DASHBOARD_PAGE__portfolioUnlockButton')
                : t('DASHBOARD_PAGE__portfolioLockButton')}
            </LockButton>
            <Tooltip
              title={
                (data.locked
                  ? t('DASHBOARD_PAGE__portfolioUnlockHelperText')
                  : t('DASHBOARD_PAGE__portfolioLockHelperText')) ?? ''
              }
              placement={'top'}
              aria-label={'Hint'}
              enterTouchDelay={10}
              leaveTouchDelay={5000}
            >
              <LockCellHelperIcon />
            </Tooltip>
          </div>*/}
        </LockButtonCell>
      ) : (
        <>
          <PriceCell>{price}</PriceCell>
          <ChangeCell
            $positiveChange={
              isNumber(data.change24Percentage) && data.change24Percentage !== 0
                ? data.change24Percentage > 0
                : undefined
            }
          >
            {changePercent}
          </ChangeCell>
        </>
      )}

      <TokenAmountCell>
        {data.amountCoins.toLocaleString('nl-NL')}
      </TokenAmountCell>
      <ValueCell>{value}</ValueCell>
      <PercentageCell>{getPercentages(data.percentage)}</PercentageCell>
    </TableRow>
  );
};

export type PortfolioTableRowData = Pick<
  PortfolioRowResponse['currency'],
  'id' | 'symbol' | 'title' | 'isFiat' | 'eurValue' | 'usdValue' | 'messari'
> &
  Pick<
    PortfolioRowResponse,
    | 'change24Percentage'
    | 'usdChange24Percentage'
    | 'cost'
    | 'usdCost'
    | 'percentage'
    | 'amountCoins'
  > & {
    color: string;
    icon: string;
    locked: boolean;
  };

/*const LockButton = styled(Button)<{
  $locked: boolean;
}>`
  flex: 0 0 100px;

  .MuiButton-contained {
    padding: 8px;
    background-color: ${({ $locked }) =>
      $locked ? getColor('green') : getColor('flamingo')};
    font-size: 14px;
    color: #fff;

    &:hover {
      background-color: ${getColor('darkBlue')};
    }
  }
`;*/

/*const LockCellHelperIcon = styled(QuestionMarkInCircleIcon)`
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  margin-left: 8px;
  color: ${getColor('shuttleGray')};
`;*/

const NameCellText = styled(Text)`
  font-size: 15px;
  line-height: 1.3;
  font-weight: 600;
  white-space: nowrap;
  span {
    display: block;
    font-size: 11px;
    color: ${getColor('flamingo')};
  }
`;

const IconImg = styled.img`
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  object-fit: contain;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 18px;
    height: 18px;
    margin-right: 7px;
  }
`;

const NameCellLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primary.main};
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

/*const tableRowHoverStyles = css`
  background-color: #a9d5fd;

  ${NameCell} {
    background-color: #a9d5fd;
  }
`;

const TableRow = styled(
  ({
    hover,
    ...props
  }: { hover: boolean; $lockedFiat: boolean } & TableRowProps) => (
    <DefTableRow hover {...props} />
  )
)`
  &:nth-of-type(odd) {
    background-color: ${colors.lightBlue};

    ${NameCell} {
      background-color: ${colors.lightBlue};
    }
  }

  &.MuiTableRow-hover:hover {
    ${tableRowHoverStyles};
  }

  ${({ hover }) =>
    hover &&
    css`
      && {
        ${tableRowHoverStyles};
      }
    `}

  ${({ $lockedFiat }) =>
    $lockedFiat &&
    css`
      .MuiTableCell-root.MuiTableCell-root {
        border-top: 1px solid ${getColor('flamingo')};
        border-bottom: 1px solid ${getColor('flamingo')};
        &:first-child {
          border-left: 1px solid ${getColor('flamingo')};
        }
        &:last-child {
          border-right: 1px solid ${getColor('flamingo')};
        }
      }
    `};
`;*/

export { PortfolioTableRow };
