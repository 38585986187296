/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import moment from 'moment';
import 'moment/locale/nl';
import { defaultLanguageLocale } from './consts';

moment.locale(defaultLanguageLocale);

export default moment;
