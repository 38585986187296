/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { css } from 'styled-components';
import { getUpMedia, getDownMedia } from './utils';

export const commonInputHalfWidthStyles = css`
  flex: 0 0 calc(50% - 5px);

  ${getDownMedia('md')} {
    flex-basis: 100%;
  }
`;

export const horizontalSectionButtonStyles = css`
  ${getUpMedia('sm')} {
    min-width: 144px;
  }

  ${getDownMedia('sm')} {
    margin-top: 20px;
  } ;
`;
