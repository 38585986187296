/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import {
  publicPathPrefix,
  authPathPrefix,
  appPathPrefix,
  adminPathPrefix,
} from './consts';
import { PageName } from './pages';

export const paths: Readonly<Omit<Record<PageName, string>, 'notFound'>> = {
  loading: `/loading`,
  logout: `/logout`,
  payInvoice: `${publicPathPrefix}/pay-invoice`,
  login: `${authPathPrefix}/login`,
  resetPassword: `${authPathPrefix}/reset-password`,
  signup: `${authPathPrefix}/signup`,
  account: `${appPathPrefix}/account`,
  dashboard: `${appPathPrefix}/dashboard`,
  invoices: `${appPathPrefix}/invoices`,
  contacts: `${appPathPrefix}/contacts`,
  verify: `${appPathPrefix}/verify`,
  adminAllUsers: `${adminPathPrefix}/all-users`,
  adminInvoices: `${adminPathPrefix}/invoices`,
  adminAccount: `${adminPathPrefix}/account`,
};

const pageTitleByPath = new Map<string, string>([
  [paths.dashboard, 'Portefeuille'],
  [paths.invoices, 'Facturen'],
  [paths.account, 'Account'],
  [paths.contacts, 'Contact'],
]);

const defTitle = 'Mercury Redstone';

export const getPageTitle = (path: string) =>
  (pageTitleByPath.has(path) && `${pageTitleByPath.get(path)} - ${defTitle}`) ||
  defTitle;
