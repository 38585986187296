/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(
  (props, ref) => <RouterLink ref={ref} {...props} />
);

type Props = ButtonProps &
  RouterLinkProps & {
    className?: string;
    to: string;
  };

const RouteButton: React.FC<Props> = ({
  children,
  className,
  to,
  ...buttonProps
}) => (
  // @ts-ignore
  <Button className={className} component={Link} to={to} {...buttonProps}>
    {children}
  </Button>
);

export { RouteButton };
