/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, {HTMLAttributes} from 'react';
import {useTranslation} from 'react-i18next';
import moment from '../../utils/moment';
import {
  BlockInfo,
  RebalanceBlock,
  RebalanceBlockLineBreaker,
  SingleLine,
} from './styled';

export type RebalanceInfoProps = {
  data: {
    aumBelowMinimum: boolean;
    date: string;
  };
} & HTMLAttributes<HTMLLIElement>;

const RebalanceInfo = ({
  data: { aumBelowMinimum, date },
  ...props
}: RebalanceInfoProps) => {
  const { t } = useTranslation();

  const currentDate = moment.utc();
  const rebalanceDate = moment.utc(date);

  const today = rebalanceDate.isSame(currentDate, 'day');

  return (
    <RebalanceBlock {...props}>
      <BlockInfo $semiBold>
        {(() => {
          if (aumBelowMinimum) {
            return t('DASHBOARD_PAGE__summaryRebalanceAumBelowMinimum');
          }

          if (today) {
            return t('DASHBOARD_PAGE__summaryRebalanceToday');
          }

          let leftDays = Math.round(
            rebalanceDate.diff(currentDate, 'days', true)
          );

          if (leftDays === 0) {
            leftDays++
          }

          const formattedRebalanceDate = rebalanceDate.format('DD-MM-YYYY');

          return (
            <>
              {t('DASHBOARD_PAGE__summaryRebalance')} {leftDays}{' '}
              {leftDays === 1
                ? t('DASHBOARD_PAGE__summaryDay')
                : t('DASHBOARD_PAGE__summaryDays')}. <RebalanceBlockLineBreaker />
              <SingleLine>{formattedRebalanceDate}</SingleLine>
            </>
          );
        })()}
      </BlockInfo>
    </RebalanceBlock>
  );
};

export { RebalanceInfo };
