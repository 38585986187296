/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModals } from '../../providers';
import { ResetPasswordForm } from '../forms';

const ResetPasswordModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals();

  useEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'reset-password',
        titleText: t('RESET_PASSWORD_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  return (
    <ResetPasswordForm closeModal={() => dispatch({ type: 'closeModal' })} />
  );
};

/*const ModalWrapper = styled(DefModalWrapper)`
  .MuiDialog-paper {
    margin: 10px;
  }
`;*/

export { ResetPasswordModal };
