/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Text, TextProps } from '../texts';

export const StepSubTitle = (props: TextProps) => (
  <Text component={'h4'} variant={'subtitle2'} align={'center'} {...props} />
);
