/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from '../../utils/sentry';
import {
  useSendAdminEmailsMutation,
  SendAdminEmailsMutationVariables,
} from '../../apollo';
import { useAlert, useModals } from '../../providers';
import { Button as DefButton } from '../buttons';

const SendEmailsAdminModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { data: { filters: variables } = {}, dispatch } =
    useModals<'sendEmailsAdmin'>();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'send-emails-admin',
        titleText: t('SEND_EMAILS_ADMIN_MODAL__title'),
      },
    });
  }, [dispatch, t]);

  const [sendEmails, { loading: sendingEmails }] = useSendAdminEmailsMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      setAlert({
        type: 'success',
        message: t('SEND_EMAILS_ADMIN_MODAL__success'),
      });
      dispatch({ type: 'closeModal' });
    },
    onError: (e) => {
      setAlert({
        type: 'error',
        message: t('SEND_EMAILS_ADMIN_MODAL__error'),
      });
      sendSentryError(e);
    },
  });

  return (
    <Button
      loading={sendingEmails}
      onClick={() => {
        sendEmails({
          variables,
        });
      }}
    >
      {t('SEND_EMAILS_ADMIN_MODAL__button')}
    </Button>
  );
};

export type SendEmailsAdminModalData = {
  filters: SendAdminEmailsMutationVariables;
};

const Button = styled(DefButton)`
  width: 100%;
  margin-top: 6px;
`;

export { SendEmailsAdminModal };
