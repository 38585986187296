/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {isString} from 'lodash-es';
import {useEffectOnce} from 'react-use';

export const useAdminSavedPageAndRow = (key: string) => {
  const localStorageKey = key;
  const value = (() => {
    try {
      const storedValue = localStorage.getItem(localStorageKey);
      return isString(storedValue) ? JSON.parse(storedValue) : null;
    } catch (e) {
      return null;
    }
  })();

  useEffectOnce(() => {
    value && localStorage.removeItem(localStorageKey);
  });

  return value;
};


