/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { ComponentPropsWithRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { visuallyHidden } from '../../styles';
import {
  boldText,
  italicLightText,
  italicSemiboldText,
  italicText,
  lightText,
  regularText,
  semiBoldText,
} from '../../utils/fonts';

export type TextProps = ComponentPropsWithRef<typeof Text>;

const Text = styled(Typography)<{
  component?: React.ElementType;
  $visuallyHidden?: boolean;
  $weight?: keyof typeof weights;
}>`
  ${({ $visuallyHidden: hidden }) => !!hidden && visuallyHidden};
  ${({ $weight }) => !!$weight && weights[$weight]}
`;

const weights = {
  reg: regularText,
  light: lightText,
  semiBold: semiBoldText,
  bold: boldText,
  italic: italicText,
  italicLight: italicLightText,
  italicSemibold: italicSemiboldText,
} as const;

export { Text };
