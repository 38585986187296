/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

const LinkedinIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path d="M2.064 10H8v22H2.064zm18.11.002c-2.174 0-3.258 1.036-4.174 1.738V10h-5.968v22H16V18.15s-.218-2.758 3.088-2.758c1.992 0 2.912 1.134 2.912 2.758V32h6.288V18.344c0-7.5-6.038-8.342-8.114-8.342zM2 5a3 3 1080 106 0 3 3 1080 10-6 0z" />
  </Icon>
);

export { LinkedinIcon };
