/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback, useEffect, useMemo, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isNull, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ApolloError } from '@apollo/client';
import { Link } from '@mui/material';
import {
  externalLinkProps,
  minutesAmountToConsiderInitialRebalanceFailed,
  SiteData,
} from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { sendSentryError } from '../../utils/sentry';
import { useAlertModal } from '../../hooks';
import {
  StartTrialStatus,
  useControllingUserQuery,
  useStartSubscriptionMutation,
  InitialRebalanceTimeFragmentDoc,
  ExchangeCode,
} from '../../apollo';
import { getColor } from '../../styles';
import { useAlert, useExchangeWizard, useExchange } from '../../providers';
import { Button as DefButton } from '../buttons';
import { Text as DefText } from '../texts';

export type DepositStepProps = HTMLAttributes<HTMLDivElement>;

const ExchangeDepositStep = (props: DepositStepProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();
  const { moneyAmount, moneyExist, fiatOnly } = useExchangeWizard();
  const { data: controllingUserData } = useControllingUserQuery();
  const controllingUser = controllingUserData?.getUser.controlledByUser;
  const { dispatch: dispatchAlertModal } = useAlertModal();

  const { activatePortfolioButtonDisabled, leftErrorMinutes } = useMemo(() => {
    const initialRebalanceTimeStr = exchange?.initialRebalanceTime;

    const initialRebalanceTime =
      initialRebalanceTimeStr && moment.utc(initialRebalanceTimeStr);

    if (!initialRebalanceTime) {
      return {
        activatePortfolioButtonDisabled: false,
        leftErrorMinutes: null,
      };
    }

    const passedMinutes = moment.utc().diff(initialRebalanceTime, 'm');
    const leftErrorMinutes =
      minutesAmountToConsiderInitialRebalanceFailed - passedMinutes;

    return {
      activatePortfolioButtonDisabled: 0 < leftErrorMinutes,
      leftErrorMinutes: 0 < leftErrorMinutes ? leftErrorMinutes : 0,
    };
  }, [exchange]);

  const openErrorAlert = useCallback(() => {
    dispatchAlertModal(['setModalContent', 'initialPortfolioRebalanceError']);
  }, [dispatchAlertModal]);

  useEffect(() => {
    if (controllingUser) return;
    if (isNull(leftErrorMinutes)) return;

    const t = setTimeout(openErrorAlert, leftErrorMinutes * 60 * 1000);

    return () => {
      clearTimeout(t);
    };
  }, [controllingUser, leftErrorMinutes, openErrorAlert]);

  const showRebalanceInProgress = () => {
    dispatchAlertModal(['setModalContent', 'initialPortfolioRebalanceStart']);
  };

  const showStartTrialError = (error: ApolloError | Error) => {
    sendSentryError(error);
    setAlert({
      type: 'error',
      message: t('WIZARD_STEPPER__depositStepStartTrialErrorText'),
    });
  };

  const [startSubscription, { loading: startingTrial }] =
    useStartSubscriptionMutation({
      update: (cache, { data }) => {
        const status = data?.startSubscription;

        status === StartTrialStatus.Ok &&
          exchange &&
          cache.writeFragment({
            id: cache.identify(exchange),
            fragment: InitialRebalanceTimeFragmentDoc,
            broadcast: false,
            data: {
              initialRebalanceTime: moment.utc().format(),
            },
          });
      },
      onCompleted: (data) => {
        const status = data?.startSubscription;

        if (status === StartTrialStatus.Ok) {
          showRebalanceInProgress();
        } else {
          showStartTrialError(
            new Error(`Start subscription status: ${status}`)
          );
        }
      },
      onError: showStartTrialError,
    });

  if (!exchange) return null;

  return (
    <Wrapper {...props}>
      <SaldoTitle variant={'h2'} component={'h3'} align={'center'}>
        {t('WIZARD_STEPPER__depositStepSaldoText')}:{' '}
        <span>{getPrice(moneyAmount ?? 0)}</span>
      </SaldoTitle>
      {!moneyExist ? (
        <>
          {exchange.exchangeCode === ExchangeCode.Kraken && (
            <Text>
              {t('WIZARD_STEPPER__depositStepContentExtraKrakenDesc1')}{' '}
              <Link href={SiteData.KRAKEN_IDENTITY} {...externalLinkProps}>
                {t('WIZARD_STEPPER__depositStepContentExtraKrakenLink1')}
              </Link>
              .
            </Text>
          )}
          <Text>{t(descTexts[exchange.exchangeCode])}</Text>
          {exchange.exchangeCode === ExchangeCode.Bitvavo && (
            <CenterLink
              href={SiteData.BITVAVO_ADD_MONEY_LINK}
              {...externalLinkProps}
            >
              {t('WIZARD_STEPPER__bitvavoDepositStepContentLink')}
            </CenterLink>
          )}
        </>
      ) : (
        <>
          <Text style={{ padding: '0 35px', marginBottom: 0 }}>
            {t(
              fiatOnly
                ? 'WIZARD_STEPPER__depositStepFiatSuccessText'
                : 'WIZARD_STEPPER__depositStepCryptoSuccessText'
            )}
          </Text>
          <Button
            loading={startingTrial}
            disabled={activatePortfolioButtonDisabled}
            onClick={() => {
              exchange?.exchangeID &&
                startSubscription({
                  variables: {
                    exchangeId: toNumber(exchange.exchangeID),
                  },
                });
            }}
          >
            {t('WIZARD_STEPPER__depositStepSuccessButton')}
          </Button>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.section``;

const SaldoTitle = styled(DefText)`
  margin-bottom: 30px;

  span {
    color: ${getColor('blue2')};
  }
`;

const Text = styled(DefText)`
  padding-left: 15px;
  padding-right: 15px;
  margin: 10px 0 20px;
  text-align: center;
`;

const CenterLink = styled(Link)`
  display: block;
  text-align: center;
`;

const Button = styled(DefButton)`
  margin-top: 46px;
`;

const descTexts: {
  [key in ExchangeCode]: string;
} = {
  [ExchangeCode.Kraken]: 'WIZARD_STEPPER__krakenDepositStepContentDesc',
  [ExchangeCode.Bitvavo]: 'WIZARD_STEPPER__bitvavoDepositStepContentDesc',
};

export { ExchangeDepositStep };
