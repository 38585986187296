/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useCallback, useMemo } from 'react';
import { isString } from 'lodash-es';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'react-use';
import {
  SignInMutation,
  GoogleSignInMutation,
  GoogleSignUpMutation,
} from '../apollo';
import { useAlert, useModals, useReminder2FAContext } from '../providers';
import { sendSentryError } from '../utils/sentry';
import { setToken } from '../utils/auth';
import { setAuthIdentityId } from '../utils/auth-identity-id';
import { LocalStorage } from '../utils/consts';
import { setAuthVar } from '../utils/apollo-vars';

export const useSignIn = () => {
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const { dispatch } = useModals();
  const [last2FAReminderPopupShowTime = ''] = useLocalStorage<string>(
    LocalStorage.last2FAReminderPopupShownTime
  );
  const reminder2FAPopupShouldBeShown = useMemo(() => {
    if (!last2FAReminderPopupShowTime) return true;
    const lastShownTime = moment.utc(last2FAReminderPopupShowTime);
    if (!lastShownTime.isValid()) return true;
    const currentTime = moment.utc();
    return 7 <= currentTime.diff(lastShownTime, 'days');
  }, [last2FAReminderPopupShowTime]);
  const { setShouldBeShown: setReminderShouldBeShown } =
    useReminder2FAContext();

  return useCallback(
    ({
      data: { token, totpEnabled, authIdentityID },
      with2faReminder = false,
    }: CallbackParams) => {
      if (!(isString(token) && !!token)) {
        setAlert({ type: 'error', message: t('LOGIN_FORM__errorText') });
        sendSentryError(new Error(`Token is not a string, or it's empty`));
        return;
      }

      setToken(token);
      setAuthIdentityId(authIdentityID);

      if (totpEnabled) {
        dispatch({
          type: 'setModalContent',
          payload: {
            name: 'login2FA',
          },
        });
        return;
      }

      with2faReminder &&
        reminder2FAPopupShouldBeShown &&
        setReminderShouldBeShown(true);
      setAuthVar(true);
    },
    [
      setAlert,
      dispatch,
      setReminderShouldBeShown,
      reminder2FAPopupShouldBeShown,
      t,
    ]
  );
};

type CallbackParams = {
  data:
    | SignInMutation['signIn']
    | GoogleSignInMutation['googleSignIn']
    | GoogleSignUpMutation['googleSignUp'];
  with2faReminder?: boolean;
};
