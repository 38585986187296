/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { Text as DefText, TextProps } from '../texts';

export const Text = styled((props: TextProps) => (
  <DefText component={'p'} variant={'subtitle2'} align={'center'} {...props} />
))`
  margin: 10px 26px 0;
`;
