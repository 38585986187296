/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Tooltip } from '@mui/material';
import { GoogleLogin } from 'react-google-login';
import { sendSentryError } from '../../utils/sentry';
import { useAlert } from '../../providers';
import { Button as DefButton } from '../buttons';
import { GoogleIcon } from '../icons';

export type GoogleSigninButtonProps = {
  text: string;
  onSuccess: (tokenId: string) => void;
};

const GoogleSigninButton = ({ text, onSuccess }: GoogleSigninButtonProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const [incognitoMode, setIncognitoMode] = useState(false);

  const onError = (error: Error | ApolloError) => {
    setAlert({
      type: 'error',
      message: t('GOOGLE_AUTH_BUTTON__error'),
    });
    sendSentryError(error);
  };

  return (
    <Tooltip
      title={
        incognitoMode ? (t('GOOGLE_AUTH_BUTTON__incognitoError') as string) : ''
      }
      placement={'top'}
    >
      <div>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
          cookiePolicy={'single_host_origin'}
          prompt="select_account"
          disabled={incognitoMode}
          render={(props) => (
            <Button variant={'outlined'} color={'primary'} {...props}>
              <Icon />
              {text}
            </Button>
          )}
          onSuccess={(response) => {
            if (!('tokenId' in response)) {
              return onError(new Error('No tokenId in google response'));
            }
            onSuccess(response.tokenId);
          }}
          onFailure={(error) => {
            if ('error' in error) {
              switch (error.error) {
                case 'popup_closed_by_user':
                  return;
                case 'idpiframe_initialization_failed':
                  setIncognitoMode(true);
                  return;
              }
            }

            onError(error);
          }}
        />
      </div>
    </Tooltip>
  );
};

const Icon = styled(GoogleIcon)`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const Button = styled(DefButton)`
  .MuiButton-root {
    position: relative;
  }
`;

export { GoogleSigninButton };
