/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { TableCell as DefTableCell } from '@mui/material';

export const TableCell = styled(DefTableCell)`
  padding: 12px 10px;
  font-size: 15px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 12px 6px;
  }
`;
