/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { Text, TextProps } from '../texts';

type SectionTitleProps = { $noBottomMargin?: boolean } & TextProps;

export const SectionTitle = styled(
  ({ $noBottomMargin, ...props }: SectionTitleProps) => (
    <Text component={'h2'} variant={'h3'} {...props} />
  )
)`
  margin-bottom: ${({ $noBottomMargin }) => ($noBottomMargin ? 0 : 16)}px;
`;
