/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

const TwitterIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path d="M31.118 3.48a13.188 13.188 0 01-4.17 1.594A6.55 6.55 0 0022.156 3a6.566 6.566 0 00-6.396 8.06A18.64 18.64 0 012.228 4.2a6.516 6.516 0 00-.888 3.3 6.563 6.563 0 002.92 5.464 6.567 6.567 0 01-2.974-.824v.082a6.572 6.572 0 005.266 6.438 6.566 6.566 0 01-2.966.112 6.574 6.574 0 006.132 4.56 13.176 13.176 0 01-8.154 2.81c-.53 0-1.052-.032-1.566-.092A18.59 18.59 0 0010.062 29c12.076 0 18.68-10.004 18.68-18.68 0-.284-.006-.568-.02-.85a13.232 13.232 0 003.28-3.394 13.11 13.11 0 01-3.77 1.034 6.585 6.585 0 002.886-3.63z" />
  </Icon>
);

export { TwitterIcon };
