/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { TableCell } from './TableCell';

export const LockButtonCell = styled(TableCell)`
  padding-top: 4px;
  padding-bottom: 4px;
  > div {
    display: flex;
    align-items: center;
  }
`;
