/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../providers';
import { paths } from '../pages';

const AuthRoute = () => {
  const authorized = useAuthContext();
  return authorized ? <Navigate to={paths.dashboard} replace /> : <Outlet />;
};

export { AuthRoute };
