/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { getColor } from '../../styles';
import { Text, TextProps } from '../texts';

export const DescSubtitle = styled((props: TextProps) => (
  <Text component={'h2'} variant={'h3'} {...props} />
))`
  margin-top: 20px;
  color: ${getColor('veniceBlue')};
`;
