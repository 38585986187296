/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { get, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikFormProps, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { serverResponseErrorsCodes } from 'utils/consts';
import { sendSentryError } from 'utils/sentry';
import { getErrorCodesOfServerResponse } from 'utils/server-helpers';
import * as Yup from 'yup';
import { useAlertModal } from 'hooks';
import { usePortfolioLiquidateMutation } from 'apollo';
import { useAlert, useExchange } from '../../providers';
import {
  bottomButtonStyles,
  commonBottomBlocksTextStyles,
} from '../../styles/dashboard-page';
import { Button as DefButton } from '../buttons';
import { Input, InputProps } from '../form-elements';
import { Text } from '../texts';

const LiquidationForm = (props: FormikFormProps) => {
  const { t } = useTranslation();
  const { dispatch: dispatchAlertModal } = useAlertModal();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();

  const [liquidate] = usePortfolioLiquidateMutation({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      dispatchAlertModal(['setModalContent', 'pendingSellCrypto']);
    },
    onError: (error: any) => {
      const lowLiquidationMoneyError = getErrorCodesOfServerResponse(
        error
      ).some((er) => er === serverResponseErrorsCodes.NOT_ENOUGH_MONEY_TO_SELL);

      const lowLeftMoneyError = getErrorCodesOfServerResponse(error).some(
        (er) => er === serverResponseErrorsCodes.NOT_ENOUGH_MONEY
      );

      let message;

      if (lowLiquidationMoneyError) {
        message = t('LIQUIDATION_FORM__lowPercentageValueToSellError');
      } else if (lowLeftMoneyError) {
        message = t('LIQUIDATION_FORM__notEnoughMinLeftFundsError');
      } else {
        message = t('LIQUIDATION_FORM__errorText');
      }

      setAlert({ type: 'error', message });

      !lowLiquidationMoneyError && !lowLeftMoneyError && sendSentryError(error);
    },
  });

  const fieldsDataByName = useMemo(() => getFieldsDataByName(t), [t]);

  const schema = useMemo(() => getSchema(t), [t]);

  return (
    <Formik
      initialValues={{
        percents: '',
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          if (!exchange?.exchangeID) {
            throw new Error('No exchange id in liquidate mutation');
          }

          await liquidate({
            variables: {
              exchangeId: toNumber(exchange.exchangeID),
              percents: parseFloat(values.percents),
            },
          });
        } catch (e) {
          sendSentryError(e);
          setAlert({
            type: 'error',
            message: t('COMMON_ERROR'),
          });
        } finally {
          setSubmitting(false);
        }
      }}
      validationSchema={schema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        isSubmitting,
      }: FormikProps<FormValues>) => (
        <Wrapper {...props}>
          <Title component={'h4'} variant={'subtitle2'}>
            {t('LIQUIDATION_FORM__title')}
          </Title>
          <Content>
            {Object.entries(values).map(([name, value]) => {
              const extraProps = get(fieldsDataByName[name], 'extraProps', {});

              return (
                <FieldInput
                  onWheel={(e) =>
                    e.target instanceof HTMLElement && e.target.blur()
                  }
                  key={name}
                  id={`liquidation-form-${name}`}
                  name={name}
                  type={'number'}
                  value={value}
                  label={fieldsDataByName[name].placeholder || name}
                  fullWidth={false}
                  margin={'none'}
                  error={touched[name] && !!errors[name]}
                  helperText={touched[name] && !!errors[name] && errors[name]}
                  onChange={handleChange}
                  {...extraProps}
                />
              );
            })}
            <Button
              type={'submit'}
              loading={isSubmitting}
              disabled={isSubmitting}
              buttonStyles={css`
                height: 100%;
              `}
            >
              {t('LIQUIDATION_FORM__buttonText')}
            </Button>
          </Content>
        </Wrapper>
      )}
    </Formik>
  );
};

type FormValues = {
  percents: string;
};

// @ts-ignore
const getSchema = (t: TFunction): Yup.SchemaOf<FormValues> =>
  Yup.object().shape({
    percents: Yup.string()
      .test({
        name: 'min-value-test',
        test: (value) =>
          !value || parseInt(value) === 100 || 1 <= parseInt(value),
        message: t('LIQUIDATION_FORM__minValueError'),
      })
      .test({
        name: 'max-value-test',
        test: (value) =>
          !value || parseInt(value) === 100 || parseInt(value) <= 75,
        message: t('LIQUIDATION_FORM__maxValueError'),
      })
      .required(t('COMMON_FORMS_ERROR__required')),
    // absoluteValue: Yup.string(),
  });

const getFieldsDataByName: (t: TFunction) => {
  [key in keyof FormValues]: {
    placeholder?: string;
    extraProps?: InputProps;
  };
} = () => ({
  percents: {
    placeholder: '%',
  },
});

const Wrapper = styled(Form)``;

const Title = styled(Text)`
  margin-bottom: 8px;
  ${commonBottomBlocksTextStyles}
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    width: 100%;
  }
`;

const FieldInput = styled(Input)`
  flex: 100%;

  .MuiInputLabel-root.Mui-disabled {
    display: none;
  }
`;

const Button = styled(DefButton)`
  ${bottomButtonStyles};
  margin-top: 22px;
  width: 100%;
` as typeof DefButton;

export { LiquidationForm };
