/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

// #3AB047

const GraphIconSummary = (props: IconProps) => (
  <Icon viewBox="0 0 11 11" {...props}>
    <g fillRule="nonzero">
      <path d="M4.354 0h2.292c.068 0 .123.021.165.064a.224.224 0 01.064.165v10.542a.224.224 0 01-.064.165.224.224 0 01-.165.064H4.354a.224.224 0 01-.165-.064.224.224 0 01-.064-.165V.229c0-.068.021-.123.064-.165A.224.224 0 014.354 0zM.23 5.5h2.29c.069 0 .124.021.166.064a.224.224 0 01.064.165v5.042a.224.224 0 01-.064.165.224.224 0 01-.165.064H.229a.224.224 0 01-.165-.064.224.224 0 01-.064-.165V5.729c0-.068.021-.123.064-.165A.224.224 0 01.229 5.5zM8.48 2.75h2.29c.069 0 .124.021.166.064a.224.224 0 01.064.165v7.792a.224.224 0 01-.064.165.224.224 0 01-.165.064H8.479a.224.224 0 01-.165-.064.224.224 0 01-.064-.165V2.979c0-.068.021-.123.064-.165a.224.224 0 01.165-.064z" />
    </g>
  </Icon>
);

export { GraphIconSummary };
