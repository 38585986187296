/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useMemo } from 'react';
import { useUserAuthIdentitiesQuery, AuthIdentityProvider } from '../../apollo';
import { ContentBlock } from './ContentBlock';
import { ChangeUserPasswordSection } from './ChangeUserPasswordSection';
import { TwoFASection } from './TwoFASection';

const SecurityBlock = () => {
  const { data: { getUser: user } = {} } = useUserAuthIdentitiesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const emailProviderAuthIdentity = useMemo(
    () =>
      user?.authIdentities.find(
        ({ provider }) => provider === AuthIdentityProvider.Email
      ),
    [user]
  );

  if (!(user && emailProviderAuthIdentity)) return null;

  return (
    <ContentBlock>
      <ChangeUserPasswordSection />
      <TwoFASection $withSplitter />
    </ContentBlock>
  );
};

export { SecurityBlock };
