/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import {
  Visibility as DefVisibility,
  VisibilityOff as DefVisibilityOff,
} from '@mui/icons-material';
import { Input, InputProps } from './Input';

export type PasswordFieldProps = InputProps;

const PasswordField = ({ type, ...props }: PasswordFieldProps) => {
  const [masked, setMasked] = useState(true);

  return (
    <Input
      type={masked ? 'password' : 'text'}
      InputProps={{
        endAdornment: (
          <IconButton edge="end" onClick={() => setMasked(!masked)}>
            {masked ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        ),
      }}
      {...props}
    />
  );
};

const commonIconStyles = () => `cursor: pointer;`;

const Visibility = styled(DefVisibility)`
  ${commonIconStyles}
`;

const VisibilityOff = styled(DefVisibilityOff)`
  ${commonIconStyles}
`;

export { PasswordField };
