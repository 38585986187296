/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { Link as DefLink, LinkProps } from '@mui/material';
import logoImg from '../assets/images/round-white-logo.svg';
import { SiteData } from '../utils/consts';

export type AuthLogoProps = LinkProps;

const AuthLogo = (props: AuthLogoProps) => (
  <Link href={SiteData.ROOT_LINK} {...props}>
    <Logo src={logoImg} />
  </Link>
);

const Link = styled(DefLink)`
  margin: 0 auto;
`;

const Logo = styled.img`
  display: block;
  width: 70px;
  height: 70px;
`;

export { AuthLogo };
