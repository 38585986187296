/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { isNumber, toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { TFunction } from 'i18next';
import { Grid } from '@mui/material';
import { GridProps, GridSpacing } from '@mui/material/Grid';
import { portfolioDataPollInterval } from '../../utils/consts';
import { sendSentryError } from '../../utils/sentry';
import { useIsTablet } from '../../hooks';
import { useDashboardDataQuery, DashboardDataQuery } from '../../apollo';
import {
  getDownMedia,
  getUpMedia,
  mobileContentSidePaddings,
  getColor,
} from '../../styles';
import { useExchange, ExchangeWizard, useAlert } from '../../providers';
import { Spinner } from '../../styled';
import { DepositDesc } from '../../components/DepositDesc';
import { LiquidationDesc as DefLiquidationDesc } from '../../components/LiquidationDesc';
import { ExchangeWizardStepper } from '../../components/content-modals';
import { ExchangesMenu as DefExchangesMenu } from '../../components/dashboard-page/ExchangesMenu';
import {
  ExchangeKeysForm,
  LiquidationForm as DefLiquidationForm,
} from '../../components/forms';
import { Graph, Portfolio, PortfolioSummary } from '../../components/graphs';
import { Text } from '../../components/texts';
import { AppPageLayout } from '../../layouts';

const DashboardPage = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  const tablet = useIsTablet();
  const spacing: GridSpacing = tablet ? 1 : 2;

  const { exchange, exchangeWizardActive } = useExchange();

  const { loading, data, refetch } = useDashboardDataQuery({
    fetchPolicy: 'no-cache',
    skip: !exchange?.exchangeID,
    pollInterval: portfolioDataPollInterval,
    variables: {
      exchangeId: toNumber(exchange?.exchangeID),
    },
    onError: (e) => {
      setAlert({
        type: 'error',
        message: t('DASHBOARD_PAGE__fetchDataError'),
      });
      sendSentryError(e);
    },
  });

  useUpdateEffect(() => {
    refetch();
  }, [exchangeWizardActive, refetch]);

  const content = useMemo(
    () =>
      data
        ? [
            getSections(t, data, spacing).slice(0, 2),
            getSections(t, data, spacing).slice(-3),
          ]
        : [],
    [t, data, spacing]
  );

  if (exchangeWizardActive) {
    return (
      <ExchangeWizard>
        <Wrapper>
          {tablet && <ExchangesMenu />}
          <ExchangeWizardStepper withExchangeTabs={tablet} />
        </Wrapper>
      </ExchangeWizard>
    );
  }

  // noinspection RequiredAttributes
  return (
    <Wrapper>
      {(() => {
        if (loading)
          return (
            <Spinner
              style={{
                margin: '0 auto',
              }}
            />
          );

        if (content.length === 0) return 'Error here';

        const [sections, bottomSections] = content;

        // noinspection RequiredAttributes
        return (
          <>
            {tablet && <ExchangesMenu />}
            {[
              { Component: Grid, items: sections },
              { Component: BottomSections, items: bottomSections },
            ].map(({ Component, items }, topIndex) => (
              <Component key={topIndex} container spacing={spacing}>
                {items.map(
                  ({ title, content, wrapperGridCellProps }, innerIndex) => (
                    // @ts-ignore
                    <Section
                      key={title}
                      component={'section'}
                      item
                      xs={12}
                      $top={topIndex === 0 && innerIndex === 0}
                      {...(wrapperGridCellProps ?? {})}
                    >
                      <SectionTitle component={'h2'} $visuallyHidden>
                        {title}
                      </SectionTitle>
                      {content}
                    </Section>
                  )
                )}
              </Component>
            ))}
          </>
        );
      })()}
    </Wrapper>
  );
};

const Wrapper = styled(AppPageLayout)`
  ${getDownMedia('md')} {
    padding-top: ${50 + mobileContentSidePaddings * 2}px;
  }
`;

const ExchangesMenu = styled(DefExchangesMenu)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 20px 18px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${getColor('blue')};
  text-align: center;
`;

const BottomSections = styled(
  ({
    rightPadding,
    ...props
  }: {
    rightPadding?: number;
  } & GridProps) => <Grid {...props} />
)`
  margin-top: 0;

  ${(rightPadding) =>
    isNumber(rightPadding) &&
    css`
      padding-right: ${rightPadding}px;
    `}

  ${getDownMedia('lg')} {
    padding-right: 0;
  }
`;

const Section = styled(Grid)<{
  $top?: boolean;
}>`
  ${getUpMedia('md')} {
    margin-top: ${({ $top }) => ($top ? 0 : 18)}px;
  }
` as typeof Grid;

const SectionTitle = styled(Text)`
  margin-bottom: 10px;
  font-size: 1.15rem;
  line-height: 1.1;
`;

const SectionContent = styled(Grid)`` as typeof Grid;

const WhiteSectionContainerWithPaddings = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 40px;
  background-color: #fff;

  @media (min-width: 1400px) {
    padding: 30px 36px 40px;
  }

  ${({ theme }) => theme.breakpoints.down('lg')} {
    padding: 20px 20px 30px;
  }
`;

const SectionContentWrapper = styled.div``;

const LiquidationDesc = styled(DefLiquidationDesc)`
  margin-bottom: 12px;
`;

const LiquidationForm = styled(DefLiquidationForm)`
  margin-top: auto;
`;

const commonSectionContentStyles: CSSProperties = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
};

const getSections: (
  t: TFunction,
  data: DashboardDataQuery,
  spacing?: GridSpacing
) => Array<{
  title: string;
  content: JSX.Element;
  wrapperGridCellProps?: GridProps;
}> = (t, data, spacing) => [
  {
    title: t('DASHBOARD_PAGE__perfomanceSectionTitle'),
    content: (
      <SectionContent container spacing={spacing || 2}>
        <Grid item xs={12} sm={9} sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Graph data={data.getPortfolioGraphData} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <PortfolioSummary data={data.getPortfolioTotals} />
        </Grid>
      </SectionContent>
    ),
  },
  {
    title: t('DASHBOARD_PAGE__indexSectionTitle'),
    content: (
      <SectionContent>
        <Portfolio
          data={{
            portfolio: data.getPortfolio,
            demoPortfolio: data.getDemoPortfolio,
            lockedCurrencies: data.getUser.currenciesLocked,
          }}
        />
      </SectionContent>
    ),
  },
  {
    title: t('DASHBOARD_PAGE__liquidationSectionTitle'),
    content: (
      <SectionContent
        style={{
          flexGrow: 1,
        }}
        container
      >
        <WhiteSectionContainerWithPaddings style={commonSectionContentStyles}>
          <SectionContentWrapper style={commonSectionContentStyles}>
            <LiquidationDesc />
            <LiquidationForm />
          </SectionContentWrapper>
        </WhiteSectionContainerWithPaddings>
      </SectionContent>
    ),
    wrapperGridCellProps: {
      lg: 4,
      style: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  {
    title: t('DASHBOARD_PAGE__depositSectionTitle'),
    content: (
      <SectionContent
        style={{
          flexGrow: 1,
        }}
        container
      >
        <WhiteSectionContainerWithPaddings style={commonSectionContentStyles}>
          <SectionContentWrapper style={commonSectionContentStyles}>
            <DepositDesc />
          </SectionContentWrapper>
        </WhiteSectionContainerWithPaddings>
      </SectionContent>
    ),
    wrapperGridCellProps: {
      lg: 4,
      style: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  {
    title: t('DASHBOARD_PAGE__keysSectionTitle'),
    content: (
      <SectionContent
        style={{
          flexGrow: 1,
        }}
        container
      >
        <WhiteSectionContainerWithPaddings style={commonSectionContentStyles}>
          <SectionContentWrapper style={commonSectionContentStyles}>
            <ExchangeKeysForm />
          </SectionContentWrapper>
        </WhiteSectionContainerWithPaddings>
      </SectionContent>
    ),
    wrapperGridCellProps: {
      lg: 4,
      style: {
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
];

export { DashboardPage };
