/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { isString } from 'lodash-es';

const localStorageName = 'saved-url-params';
type URLParams = [string, string][];

export const getSavedURLParams = (): URLParams => {
  try {
    const savedString = localStorage.getItem(localStorageName);
    return isString(savedString) ? JSON.parse(savedString) : null;
  } catch (e) {
    return [];
  }
};

export const setSavedURLParams = (params: URLParams) => {
  try {
    localStorage.setItem(localStorageName, JSON.stringify(params));
    return true;
  } catch (e) {
    return false;
  }
};

export const removeSavedURLParams = () => {
  try {
    localStorage.removeItem(localStorageName);
    return true;
  } catch (e) {
    return false;
  }
};
