/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserCompanyNameQuery } from '../../apollo';
import { SectionWrapper } from './SectionWrapper';
import { SectionTitle } from './SectionTitle';
import { UserProfileForm } from '../forms';

export type ProfileFormSectionProps = ComponentProps<typeof SectionWrapper>;

const ProfileFormSection = (props: ProfileFormSectionProps) => {
  const { t } = useTranslation();
  const { data: { getUser: user } = {} } = useUserCompanyNameQuery();

  const businessAccount = useMemo(() => !!user?.companyName, [user]);

  return (
    <SectionWrapper {...props}>
      <SectionTitle>
        {t(
          businessAccount
            ? 'USER_PROFILE_FORM__businessAccountTitle'
            : 'USER_PROFILE_FORM__privateAccountTitle'
        )}
      </SectionTitle>
      <UserProfileForm />
    </SectionWrapper>
  );
};

export { ProfileFormSection };
