/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { ContentBlock } from './ContentBlock';
import { ProfileFormSection } from './ProfileFormSection';
import { EmailChangeFormSection } from './EmailChangeFormSection';
import { PaymentSection } from './PaymentSection';

const EditUserBlock = () => (
  <ContentBlock>
    <ProfileFormSection />
    <EmailChangeFormSection $withSplitter />
    <PaymentSection $withSplitter />
  </ContentBlock>
);

export { EditUserBlock };
