/* eslint-disable @typescript-eslint/no-use-before-define */

/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
  createContext,
  useContext,
  FC,
  PropsWithChildren,
} from 'react';
import { useLocation } from 'react-router-dom';
import moment from '../utils/moment';
import { usePaymentProviderDataQuery, MollieStatus } from '../apollo';

const Payments: FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useLocation();
  const initialRenderRef = useRef(true);
  const [paymentModalOpened, setPaymentModalOpened] = useState(false);

  const { data: { getUser: user } = {} } = usePaymentProviderDataQuery();

  const paymentModalType = useMemo(() => {
    if (!user) return PaymentModalTypes.OTHER;

    const {
      mollieStatus,
      trialEndDate,
      hasActiveSubscription,
      hasUnpaidInvoices,
    } = user;
    const trialEnded =
      !!trialEndDate && moment.utc(trialEndDate) <= moment.utc();

    const noPaymentMethod = mollieStatus !== MollieStatus.Ok;

    return hasActiveSubscription && hasUnpaidInvoices
      ? PaymentModalTypes.HAS_UNPAID_INVOICE
      : trialEnded && noPaymentMethod
      ? PaymentModalTypes.TRIAL_ENDED_AND_NO_CREDIT_CARD
      : PaymentModalTypes.OTHER;
  }, [user]);

  const paymentTrialClockActive = useMemo(() => {
    if (user?.isAdmin) return false;
    const noPaymentMethod = user?.mollieStatus !== MollieStatus.Ok;
    const leftTrialIsLessThan14Days =
      moment.utc(user?.trialEndDate).diff(moment.utc({ hours: 0 }), 'days') <=
      14;

    return [
      user?.hasActiveSubscription,
      noPaymentMethod,
      paymentModalType !== PaymentModalTypes.HAS_UNPAID_INVOICE,
      leftTrialIsLessThan14Days,
    ].every(Boolean);
  }, [user, paymentModalType]);

  // Close all on pathname change
  useEffect(() => {
    setPaymentModalOpened(false);
  }, [pathname]);

  const togglePaymentModal = useCallback<ContextValue['togglePaymentModal']>(
    (on) => {
      setPaymentModalOpened((prevState) => on ?? !prevState);
    },
    []
  );

  // Set timer for 2 credit card cases
  useEffect(() => {
    if (!user || user.isAdmin || !user.hasActiveSubscription) return;

    if (
      ![
        PaymentModalTypes.HAS_UNPAID_INVOICE,
        PaymentModalTypes.TRIAL_ENDED_AND_NO_CREDIT_CARD,
      ].some((type) => type === paymentModalType)
    ) {
      return;
    }

    if (initialRenderRef.current) {
      togglePaymentModal(true);
      initialRenderRef.current = false;
    }

    const timer = setInterval(() => {
      togglePaymentModal(true);
    }, 5 * 60 * 1000);

    return () => {
      timer && clearInterval(timer);
    };
  }, [user, paymentModalType, togglePaymentModal]);

  const contextValue = useMemo<ContextValue>(
    () => ({
      paymentModalType,
      paymentModalOpened,
      paymentTrialClockActive,
      togglePaymentModal,
    }),
    [
      paymentModalType,
      paymentModalOpened,
      paymentTrialClockActive,
      togglePaymentModal,
    ]
  );

  if (!user) return null;

  return (
    <PaymentsContext.Provider value={contextValue}>
      {children}
    </PaymentsContext.Provider>
  );
};

type State = {
  paymentModalType: PaymentModalTypes;
  paymentModalOpened: boolean;
  paymentTrialClockActive: boolean;
};

export enum PaymentModalTypes {
  HAS_UNPAID_INVOICE,
  TRIAL_ENDED_AND_NO_CREDIT_CARD,
  OTHER,
}

const initialState: Readonly<State> = {
  paymentModalType: PaymentModalTypes.OTHER,
  paymentModalOpened: false,
  paymentTrialClockActive: false,
};

type ContextValue = State & {
  togglePaymentModal: (on?: boolean) => void;
};

const PaymentsContext = createContext<ContextValue>({
  ...initialState,
  togglePaymentModal: () => undefined,
});

export const usePayments = () => useContext(PaymentsContext);

export { Payments };
