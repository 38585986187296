/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import moment from 'moment';
import download from 'downloadjs';
import { getToken } from './auth';

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

const downloadFileResponseHandler = (response: AxiosResponse<any>) => {
  const content = response.headers['content-type'];
  const fileName =
    (response.headers['content-disposition'] || '').split('filename=')[1] ||
    'invoice.pdf';
  console.log('response.data', response.data);
  console.log('fileName', fileName);
  console.log('content', content);
  download(response.data, fileName, content);
};

enum ApiPaths {
  GET_INVOICES = '/get-invoices',
}

type ApiCallData = {
  getInvoices: {
    inputData: {
      from: string;
      till: string;
    };
    result: void;
  };
};

/*type Merge<A, B> = {
  [K in keyof A]: K extends keyof B ? B[K] : A[K];
} & B extends infer O
  ? { [K in keyof O]: O[K] }
  : never;*/

const apiCallConfigs: {
  [T in keyof ApiCallData]: {
    config: AxiosRequestConfig;
    inputDataHandler?: (
      data: ApiCallData[T]['inputData']
    ) => AxiosRequestConfig | undefined;
    responseDataHandler?: (response: AxiosResponse) => ApiCallData[T]['result'];
  } & ApiCallData[T];
} = {
  getInvoices: {
    config: {
      url: ApiPaths.GET_INVOICES,
      method: 'GET',
      headers: {
        Authorization: getToken() ?? '',
      },
      responseType: 'blob',
      /*headers: {
        'Content-Type': 'multipart/form-data',
      },*/
    },
    inputData: {
      from: '',
      till: '',
    },
    result: undefined,
    inputDataHandler({ from, till }) {
      if (!(from && till)) return undefined;
      const format = 'DD-MM-YYYY';
      const fromFormatted = moment.utc(from).format(format);
      const tillFormatted = moment.utc(till).format(format);
      return {
        url: `${this.config.url}/${fromFormatted}/${tillFormatted}`,
      };
    },
    responseDataHandler: downloadFileResponseHandler,
  },
};

export const callApi = <T extends keyof ApiCallData>(
  type: T,
  data: ApiCallData[T]['inputData']
) => {
  const config = apiCallConfigs[type].inputDataHandler?.(data);
  return apiInstance
    .request<ApiCallData[T]['result']>({
      ...apiCallConfigs[type].config,
      ...config,
    })
    .then((responseResult) => {
      const handler = apiCallConfigs[type].responseDataHandler;
      return handler ? handler(responseResult) : responseResult;
    });
};
