/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useTranslation } from 'react-i18next';
import { SectionWrapper } from './SectionWrapper';
import { SectionTitle } from './SectionTitle';
import { ChangeUserPasswordForm } from '../forms';
import { ComponentProps } from 'react';

export type ChangeUserPasswordSectionProps = ComponentProps<
  typeof SectionWrapper
>;

const ChangeUserPasswordSection = (props: ChangeUserPasswordSectionProps) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper {...props}>
      <SectionTitle>{t('CHANGE_USER_PASSWORD_FORM__title')}</SectionTitle>
      <ChangeUserPasswordForm />
    </SectionWrapper>
  );
};

export { ChangeUserPasswordSection };
