/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { pick, isEmpty, compact } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import {
  Table as DefTable,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableCell,
  TablePagination,
  TableProps,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { tablesData } from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { semiBoldText } from '../../utils/fonts';
import { isLoadingApolloStatus } from '../../utils/helpers';
import moment from '../../utils/moment';
import { getPercentages } from '../../utils/percentages';
import { sendSentryError } from '../../utils/sentry';
import { invoiceStatusesTranslations } from '../../utils/translations';
import {
  useAdminUsersFirstAndLastNamesQuery,
  useAdminInvoicesQuery,
  OrderByDirection,
  InvoicesOrderByField,
  InvoicesFilterByStatus,
  AdminInvoicesQuery,
  AdminInvoicesQueryVariables,
} from '../../apollo';
import { getDownMedia } from '../../styles';
import { Spinner as DefSpinner } from '../../styled';
import { DownloadFileButton } from '../buttons';
import { Select, Input } from '../form-elements';
import { DownloadInvoicesForm } from '../forms';
import { SortLink, Text } from '../texts';

const AdminInvoicesTable = (props: TableProps) => {
  const { t } = useTranslation();

  const [chosenSelectUserId, setChosenSelectUserId] = useState('');

  const [chosenInvoiceStatus, setChosenInvoiceStatus] = useState<
    InvoicesFilterByStatus | ''
  >('');

  const [userNameInputFilterValue, setUserNameInputFilterValue] = useState('');

  const [userNameFilter, setUserNameFilter] = useState('');

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(
    tablesData.adminInvoices.initialRows
  );

  const [columnSortings, setColumnSortings] = useState<
    Array<{
      field: typeof sortableColumns[number];
      value: OrderByDirection;
    }>
  >([]);

  useDebounce(
    () => {
      setUserNameFilter(userNameInputFilterValue);
    },
    600,
    [userNameInputFilterValue]
  );

  const { data: { adminGetUsers } = {} } = useAdminUsersFirstAndLastNamesQuery({
    fetchPolicy: 'no-cache',
  });

  const invoiceStatusesOptions = useMemo(
    () =>
      Object.values(InvoicesFilterByStatus).map((key) => ({
        label: t(invoiceStatusesTranslations[key]),
        value: key,
      })),
    [t]
  );

  const usersOptions = useMemo(
    () =>
      adminGetUsers?.users
        .map(({ id, firstName, lastName }) => ({
          label: `${firstName} ${lastName}`,
          value: id,
        }))
        .sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();

          if (nameA < nameB) return -1;

          if (nameA > nameB) return 1;

          return 0;
        }) ?? [],
    [adminGetUsers]
  );

  const adminInvoicesQueryVariables =
    useMemo<AdminInvoicesQueryVariables>(() => {
      const chosenUserId = parseInt(chosenSelectUserId);
      const ids = !isNaN(chosenUserId) ? [chosenUserId] : undefined;

      const orderBy: Array<{
        Field: InvoicesOrderByField;
        Direction: OrderByDirection;
      }> = [];

      if (columnSortings) {
        compact(
          columnSortings.map(({ field, value }) => {
            const sortField = sortFieldsByColumnName[field];
            if (!sortField) return null;
            return {
              Field: sortField,
              Direction: value,
            };
          })
        ).forEach((item) => orderBy.push(item));
      }

      return {
        ids,
        status: !isEmpty(chosenInvoiceStatus)
          ? (chosenInvoiceStatus as InvoicesFilterByStatus)
          : undefined,
        orderBy: !isEmpty(orderBy) ? orderBy : undefined,
      };
    }, [chosenSelectUserId, chosenInvoiceStatus, columnSortings]);

  const {
    networkStatus,
    error,
    data: { adminGetInvoices: rawInvoices = [] } = {},
  } = useAdminInvoicesQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 60000,
    variables: adminInvoicesQueryVariables,
    notifyOnNetworkStatusChange: true,
    onError: sendSentryError,
  });

  const invoices = useMemo(
    () =>
      rawInvoices
        .filter(
          ({ user: { firstName, lastName } }) =>
            !userNameFilter ||
            [`${firstName} ${lastName}`, `${lastName} ${firstName}`]
              .map((value) => value.toLocaleLowerCase())
              .some((value) => value.includes(userNameFilter))
        )
        .map(addUserNameFieldToInvoice)
        .map(modifyInvoiceFieldValues),
    [rawInvoices, userNameFilter]
  );

  if (error) {
    return <span>{t('INVOICES_PAGE__loadError')}</span>;
  }

  if (isLoadingApolloStatus(networkStatus)) {
    return <Spinner />;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSortingChange = (key: typeof sortableColumns[number]) => {
    const currentValue = columnSortings.find(
      ({ field }) => field === key
    )?.value;

    let nextValue;

    switch (currentValue) {
      case OrderByDirection.Desc:
        nextValue = OrderByDirection.Asc;
        break;
      case OrderByDirection.Asc:
        nextValue = undefined;
        break;
      default:
        nextValue = OrderByDirection.Desc;
    }

    setColumnSortings(
      nextValue
        ? [
            {
              field: key,
              value: nextValue,
            },
          ]
        : []
    );
  };

  const getSortDirection = (key: typeof sortableColumns[number]) =>
    columnSortings.find(({ field }) => field === key)?.value;

  return (
    <>
      <Header>
        <Filters>
          <FilterSelect
            items={usersOptions}
            label={t('INVOICES_PAGE__filterByUser')}
            resetOptionText={'Reset'}
            value={chosenSelectUserId}
            onChange={({ target: { value } }) => {
              setChosenSelectUserId(value);
            }}
          />
          <FilterSelect
            items={invoiceStatusesOptions}
            label={t('INVOICES_PAGE__filterByStatus')}
            resetOptionText={'Reset'}
            value={chosenInvoiceStatus}
            onChange={({ target: { value } }) => {
              // @ts-ignore
              setChosenInvoiceStatus(value);
            }}
          />
          <InputInput
            label={t('INVOICES_PAGE__filterByUserName')}
            value={userNameInputFilterValue}
            onChange={({ target: { value } }) => {
              setUserNameInputFilterValue(value);
            }}
          />
        </Filters>
        <InvoicesForm />
      </Header>
      {isEmpty(rawInvoices) ? (
        <NoInvoicesText>{t('INVOICES_PAGE__noInvoices')}</NoInvoicesText>
      ) : (
        <Table {...props}>
          <TableHead>
            <TableRow>
              {(
                Object.entries(columns) as Array<
                  [keyof typeof columns, typeof columns[keyof typeof columns]]
                >
              ).map(([column, rawTitle], index) => {
                const title = rawTitle ? t(rawTitle) : '';
                const sortable = sortableColumns.includes(column);

                return (
                  <TableCell key={index}>
                    {sortable ? (
                      <SortLink
                        direction={getSortDirection(column)}
                        onClick={() => onSortingChange(column)}
                      >
                        {title}
                      </SortLink>
                    ) : (
                      title
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((invoice, index) => (
                <TableRow key={index}>
                  {Object.keys(columns).map((key, index) => {
                    const value = invoice[key];

                    if (key !== 'url') {
                      return (
                        <TableCell key={index}>{value as string}</TableCell>
                      );
                    }

                    return (
                      <TableCell key={index}>
                        <DownloadFileButton filePath={value as string}>
                          {t('INVOICES_PAGE__tableDownloadButtonText')}
                        </DownloadFileButton>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={tablesData.adminInvoices.rowsPerPageOptions}
                colSpan={6}
                count={invoices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                labelRowsPerPage={`${t('INVOICES_PAGE__tableRowsPerPage')}:`}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </>
  );
};

type InvoiceResponse = AdminInvoicesQuery['adminGetInvoices'][number];

type Invoice = Omit<InvoiceResponse, 'user'> & {
  userName: string;
};

const Header = styled.header`
  display: flex;
  margin-bottom: 16px;

  ${getDownMedia('lg')} {
    flex-wrap: wrap;
  }
`;

const marginBetweenFilters = 5;

const FilterSelect = styled(Select)``;

const InputInput = styled(Input)``;

const Filters = styled.div`
  display: flex;
  margin-left: -${marginBetweenFilters}px;
  margin-right: 5px;

  ${getDownMedia('lg')} {
    flex-grow: 1;
    margin-right: -${marginBetweenFilters}px;
  }

  ${FilterSelect}, ${InputInput} {
    min-width: 140px;
    margin-left: ${marginBetweenFilters}px;
    margin-right: ${marginBetweenFilters}px;
  }
`;

const InvoicesForm = styled(DownloadInvoicesForm)`
  margin-left: auto;

  ${getDownMedia('lg')} {
    flex-grow: 1;
  }
`;

const Table = styled(DefTable)`
  .MuiTableCell-root {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;

    &:last-child {
      width: 9%;
      padding-right: 0;
      min-width: 80px;
    }
  }

  .MuiTableCell-head {
    padding-bottom: 22px;
    ${semiBoldText};
  }

  .MuiTableCell-footer {
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .MuiButton-contained {
    padding: 8px 10px;
  }
`;

const Spinner = styled(DefSpinner)`
  margin: 0 auto;
`;

const NoInvoicesText = styled(Text)`
  padding-top: 40px;
  text-align: center;
`;

// const initialSortings: Sortings = [];

const columns: Readonly<{
  [key in keyof Invoice]: string;
}> = {
  dueDate: 'INVOICES_PAGE__tableDateColumnName',
  userName: 'INVOICES_PAGE__tableUserNameColumnName',
  price: 'INVOICES_PAGE__tablePriceColumnName',
  vat: 'INVOICES_PAGE__tableVATColumnName',
  total: 'INVOICES_PAGE__tableTotalColumnName',
  url: '',
};

const sortableColumns: Array<keyof typeof columns> = ['dueDate', 'price'];

const sortFieldsByColumnName: {
  [key in typeof sortableColumns[number]]?: InvoicesOrderByField;
} = {
  dueDate: InvoicesOrderByField.DueDate,
  price: InvoicesOrderByField.Cost,
};

const addUserNameFieldToInvoice = (invoice: InvoiceResponse): Invoice => {
  const pickFields = ['dueDate', 'price', 'vat', 'total', 'url'];
  const pickedInvoice = pick(invoice, pickFields) as Omit<Invoice, 'userName'>;

  return Object.entries(pickedInvoice).reduce(
    (res, [key, value], index) => ({
      ...res,
      ...(index === 1
        ? {
            userName: `${invoice.user.firstName} ${invoice.user.lastName}`,
            [key]: value,
          }
        : { [key]: value }),
    }),
    {}
  ) as Invoice;
};

const modifyInvoiceFieldValues = (invoice: Invoice) =>
  Object.entries(invoice).reduce((res, [key, value]) => {
    if (key === 'dueDate') {
      return {
        ...res,
        [key]: moment.utc(value as string).format('MM-DD-YY'),
      };
    }

    if (key === 'price' || key === 'total') {
      return {
        ...res,
        [key]: getPrice(value as number),
      };
    }

    if (key === 'vat') {
      return {
        ...res,
        [key]: getPercentages(value as number),
      };
    }

    return {
      ...res,
      [key]: value,
    };
  }, {});

export { AdminInvoicesTable };
