/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { paths } from '../pages';
import { colors } from '../utils/colors';
import { lightText, semiBoldText } from '../utils/fonts';
import { RouteLink, Text } from './texts';

export type MobileAuthPageChangeBlockProps = HTMLAttributes<HTMLDivElement>;

const AuthPageChangeBlock = (props: MobileAuthPageChangeBlockProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  if (![paths.login, paths.signup].some((authPath) => authPath === pathname)) {
    return null;
  }

  const data = {
    [paths.login]: {
      desc: t('LOGIN_PAGE__mobileSignupText'),
      url: paths.signup,
      urlText: t('LOGIN_HEADER__signUpText'),
    },
    [paths.signup]: {
      desc: t('SIGNUP_PAGE__mobileSignupText'),
      url: paths.login,
      urlText: t('LOGIN_HEADER__loginText'),
    },
  };

  const { desc, url, urlText } = data[pathname];

  return (
    <Wrapper {...props}>
      <Desc>{desc}</Desc>
      <Link to={url}>{urlText}</Link>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;
`;

const Desc = styled(Text)`
  margin-bottom: 4px;
  font-size: 0.9rem;
  line-height: 1;
  color: ${colors.shuttleGray};
  ${lightText};
`;

const Link = styled(RouteLink)`
  ${semiBoldText};
`;

export { AuthPageChangeBlock };
