/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link as DefLink, LinkProps } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { OrderByDirection, Maybe } from '../../apollo';

export type SortLinkProps = {
  direction: Maybe<OrderByDirection>;
} & LinkProps;

const SortLink: FC<SortLinkProps> = ({ children, direction, ...props }) => {
  const icon = direction ? sortIcons[direction] : null;
  return (
    <Link {...props}>
      {children}
      {icon ? <> {icon}</> : ''}
    </Link>
  );
};

const Link = styled(DefLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;
const sortIconStyles = css`
  width: 18px;
  height: 18px;
`;
const SortDownIcon = styled(ExpandMore)`
  ${sortIconStyles}
`;
const SortUpIcon = styled(ExpandLess)`
  ${sortIconStyles}
`;
const sortIcons = {
  [OrderByDirection.Asc]: <SortUpIcon />,
  [OrderByDirection.Desc]: <SortDownIcon />,
};

export { SortLink };
