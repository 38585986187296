/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setSavedURLParams } from '../utils/saved-url-params';
import { isEmpty } from 'lodash-es';

export const useSetSavedURLParams = () => {
  const { search } = useLocation();

  const params = useMemo(() => {
    try {
      return [...new URLSearchParams(search).entries()];
    } catch (e) {
      return [];
    }
  }, [search]);

  useEffect(() => {
    if (!isEmpty(params)) {
      setSavedURLParams(params);
    }
  }, [params]);
};
