/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from './Title';
import { Icon } from './Icon';

const ChangeEmailSuccess = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon type={'success'} />
      <Title>{t('NOTIFICATION_CHANGE_EMAIL_SUCCESS_MODAL__title')}</Title>
    </>
  );
};

export { ChangeEmailSuccess };
