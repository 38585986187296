/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useMediaQuery, useTheme } from '@mui/material';

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};
