/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useTranslation } from 'react-i18next';
import {
  usePortfolioDepositMutation,
  PortfolioDepositMutationOptions,
} from '../apollo';
import { sendSentryError } from '../utils/sentry';
import { getErrorCodesOfServerResponse } from '../utils/server-helpers';
import { serverResponseErrorsCodes } from '../utils/consts';
import { useAlert } from '../providers';

export const usePortfolioDeposit = (
  options: PortfolioDepositMutationOptions
) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();

  return usePortfolioDepositMutation({
    onError: (error) => {
      sendSentryError(error);

      const lowMoneyError = getErrorCodesOfServerResponse(error).some(
        (er) => er === serverResponseErrorsCodes.NOT_ENOUGH_MONEY_TO_PURCHASE
      );

      setAlert({
        type: 'error',
        message: t(
          lowMoneyError
            ? 'DEPOSIT_FORM__notEnoughFundsError'
            : 'DEPOSIT_DESC__errorText'
        ),
      });
    },
    ...options,
  });
};
