/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import axios from 'axios';

export const checkFileExistence = (fileUrl: string) =>
  axios
    .head(fileUrl)
    .then((response) => {
      if (response.statusText === 'OK') return fileUrl;
      return false as const;
    })
    .catch(() => false as const);
