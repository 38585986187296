/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import { paths } from '../paths';
import { setToken } from '../../utils/auth';
import { colors } from '../../utils/colors';
import { AuthLayout } from '../../layouts';
import { SetNewPasswordForm as DefSetNewPasswordForm } from '../../components/forms/SetNewPasswordForm';
import { Title, RouteLink, Text } from '../../components/texts';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [tokenAvailable, setTokenAvailable] = useState(false);

  useLayoutEffect(() => {
    const params = parse(location.search);
    if (!params.c) return;

    setToken(params.c as string);
    setTokenAvailable(true);
  }, [location.search]);

  return (
    <AuthLayout>
      <Title withLine gutterBottom>
        {t('RESET_PASSWORD_PAGE__title')}
      </Title>
      {tokenAvailable ? (
        <SetNewPasswordForm />
      ) : (
        <Error>{t('RESET_PASSWORD_PAGE__noToken')}</Error>
      )}
      <BottomBlock>
        <BottomText>
          {t('RESET_PASSWORD_PAGE__loginText')}{' '}
          <RouteLink to={paths.login} underline={'always'}>
            {t('RESET_PASSWORD_PAGE__loginButtonText')}
          </RouteLink>
        </BottomText>
      </BottomBlock>
    </AuthLayout>
  );
};

const SetNewPasswordForm = styled(DefSetNewPasswordForm)`
  margin-bottom: 40px;
`;

const Error = styled(Text)`
  text-align: center;
  margin-bottom: 20px;
  color: ${colors.orange};
`;

const BottomBlock = styled.div``;

const BottomText = styled(Text)`
  text-align: center;
`;

export { ResetPasswordPage };

export default ResetPasswordPage;
