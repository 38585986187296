/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { Outlet } from 'react-router-dom';
import { AppProviders } from '../providers';
import { LayoutWithAsideMenu } from '../layouts';
import { Wrapper } from '../styled';
import { useServerErrorVar } from '../utils/apollo-vars';
import { ServerIsDownModal } from './modals';
import { PrivateRoute } from './PrivateRoute';

const AppAndAdminRoutesWrapper = () => {
  const serverError = useServerErrorVar();

  return (
    <PrivateRoute>
      <AppProviders>
        <LayoutWithAsideMenu>
          {!serverError ? (
            <Wrapper>
              <Outlet />
            </Wrapper>
          ) : (
            <ServerIsDownModal />
          )}
        </LayoutWithAsideMenu>
      </AppProviders>
    </PrivateRoute>
  );
};

export { AppAndAdminRoutesWrapper };
