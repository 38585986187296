/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { TotpValidateResponse } from '../../apollo';
import { useModals } from '../../providers';
import { EnableTwoFactorForm } from '../forms';
import { BackupCodes } from '../BackupCodes';

const Enable2FAModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals();
  const [backupCodes, setBackupCodes] = useState<
    TotpValidateResponse['backupCodes']
  >([]);

  const title = useMemo(
    () =>
      t(
        isEmpty(backupCodes)
          ? 'ENABLE_2FA_MODAL__title'
          : 'ENABLE_2FA_MODAL__backupCodesTitle'
      ),
    [backupCodes, t]
  );

  const closeModal = useCallback(
    () => dispatch({ type: 'closeModal' }),
    [dispatch]
  );

  useEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'enable-2-fa',
        titleText: title,
      },
    });
  }, [dispatch, title, closeModal]);

  return isEmpty(backupCodes) ? (
    <EnableTwoFactorForm setBackupCodes={setBackupCodes} />
  ) : (
    <BackupCodes codes={backupCodes} onButtonClick={closeModal} />
  );
};

export { Enable2FAModal };
