/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

// eslint-disable-next-line import/default
import ReactGA from 'react-ga';

// eslint-disable-next-line import/no-named-as-default-member
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS ?? '');

export { ReactGA };
