/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { removeToken } from '../utils/auth';
import { removeAuthIdentityId } from '../utils/auth-identity-id';

export const useLogout = () => {
  const client = useApolloClient();

  useEffect(() => {
    removeToken();
    removeAuthIdentityId();

    client.resetStore().catch((e) => console.log('error', e));
  }, [client]);
};
