/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useEffect } from 'react';
import styled from 'styled-components';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { sendSentryError } from 'utils/sentry';
import { exchangesCodesTranslations } from '../../utils/translations';
import { useAlertModal } from '../../hooks';
import {
  useDisableSubscriptionMutation,
  ExchangeCode,
  AumBelowMinLastShowTimeAndInitialRebalanceTimeFragmentDoc,
  AumBelowMinLastShowTimeAndInitialRebalanceTimeFragment,
} from 'apollo';
import { getDownMedia } from '../../styles';
import { useAlert, useModals } from '../../providers';
import { Button as DefButton } from '../buttons';
import { Text as DefText } from '../texts';

const DisableSubscriptionModal = () => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { data, dispatch: dispatchModal } = useModals<'disableSubscription'>();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  useEffect(() => {
    dispatchModal({
      type: 'setWrapperProps',
      payload: {
        modalName: 'disable-subscription',
        titleText: `${t('DISABLE_SUBSCRIPTION_MODAL__title1')} ${
          data?.exchangeCode
            ? t(exchangesCodesTranslations[data.exchangeCode])
            : ''
        } ${t('DISABLE_SUBSCRIPTION_MODAL__title2')}`,
      },
    });
  }, [dispatchModal, t, data]);

  const [disableSubscription, { loading: disablingSubscription }] =
    useDisableSubscriptionMutation({
      variables: {
        exchangeId: toNumber(data?.exchangeID),
      },
      update: (cache, { data }) => {
        data?.cancelSubscription &&
          cache.writeFragment<AumBelowMinLastShowTimeAndInitialRebalanceTimeFragment>(
            {
              id: cache.identify(data.cancelSubscription),
              fragment:
                AumBelowMinLastShowTimeAndInitialRebalanceTimeFragmentDoc,
              broadcast: false,
              data: {
                aumBelowMinLastShowTime: null,
                initialRebalanceTime: null,
              },
            }
          );
      },
      onCompleted: () => {
        dispatchModal({ type: 'closeModal' });
        dispatchAlertModal(['setModalContent', 'subscriptionDisabledSuccess']);
      },
      onError: (error) => {
        setAlert({
          type: 'error',
          message: t('DISABLE_SUBSCRIPTION_MODAL__errorText'),
        });
        sendSentryError(error);
      },
    });

  return (
    <>
      <Text>{data?.exchangeCode ? t(descs[data.exchangeCode]) : ''}</Text>
      <Button
        loading={disablingSubscription}
        onClick={() => {
          disableSubscription();
        }}
      >
        {t('DISABLE_SUBSCRIPTION_MODAL__disableSubscriptionButtonText')}
      </Button>
    </>
  );
};

export type DisableSubscriptionModalData = {
  exchangeID: string;
  exchangeCode: ExchangeCode;
};

const Text = styled(DefText)`
  margin-bottom: 30px;
  margin-right: 60px;

  ${getDownMedia('sm')} {
    margin-right: 0;
  }
`;

const Button = styled(DefButton)``;

const descs: {
  [key in ExchangeCode]: string;
} = {
  [ExchangeCode.Bitvavo]: 'DISABLE_SUBSCRIPTION_MODAL__bitvavoDesc',
  [ExchangeCode.Kraken]: 'DISABLE_SUBSCRIPTION_MODAL__krakenDesc',
};

export { DisableSubscriptionModal };
