/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { FormikHandlers } from 'formik';
import styled from 'styled-components';
import {
  FormControl,
  FormLabel,
  RadioGroup as DefRadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

const RadioGroup = styled(DefRadioGroup)``;

const Wrapper = styled(FormControl)<{ component: any }>``;

type Props = {
  className?: string;
  label: string;
  name: string;
  value: string;
  options: Array<{
    label: string;
    value: string;
  }>;
  onChange: FormikHandlers['handleChange'];
};

const RadioButtons = ({
  className,
  label,
  name,
  value,
  options,
  onChange,
}: Props) => (
  <Wrapper className={className} component={'fieldset'}>
    {!!label && <FormLabel component="legend">{label}</FormLabel>}
    <RadioGroup
      row
      aria-label={name}
      name={name}
      value={value}
      onChange={onChange}
    >
      {options.map(({ label, value: inputValue }) => (
        <FormControlLabel
          key={label}
          label={label}
          value={inputValue}
          control={<Radio />}
        />
      ))}
    </RadioGroup>
  </Wrapper>
);

RadioButtons.defaultProps = {};

export { RadioButtons };
