/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { semiBoldText } from '../../utils/fonts';
import { getUpMedia } from '../../styles';
import { useModals } from '../../providers';
import { MobileBr } from 'styled';
import img from '../../assets/images/initial-portfolio.svg';
import { Button as DefButton } from '../buttons';
import { Text as DefText, Link as DefLink, TextProps } from '../texts';

const InitialPortfolioModal = () => {
  const { t } = useTranslation();
  const { dispatch } = useModals();

  useLayoutEffect(() => {
    dispatch({
      type: 'setWrapperProps',
      payload: {
        modalName: 'initial-portfolio',
        styleType: 'wide',
        alwaysContentScrollbar: true,
      },
    });
  }, [dispatch, t]);

  return (
    <>
      <Img src={img} alt={'Initial portfolio'} />
      <Title variant={'h1'} id={'initial-portfolio-modal-title'}>
        {t('INITIAL_PORTFOLIO_MODAL__title1')}
        <MobileBr />
        {t('INITIAL_PORTFOLIO_MODAL__title2')}
      </Title>
      <FirstParagraph>
        {t('INITIAL_PORTFOLIO_MODAL__text1')}{' '}
        <Link
          onClick={() => {
            dispatch({
              type: 'setModalContent',
              payload: {
                name: 'bitvavoInfoModal',
                data: {
                  initialPortfolioModalAfterClose: true,
                },
              },
            });
          }}
        >
          {t('INITIAL_PORTFOLIO_MODAL__link1')}
        </Link>{' '}
        {t('INITIAL_PORTFOLIO_MODAL__text2')}{' '}
        <Link
          onClick={() => {
            dispatch({
              type: 'setModalContent',
              payload: {
                name: 'krakenInfoModal',
                data: {
                  initialPortfolioModalAfterClose: true,
                },
              },
            });
          }}
        >
          {t('INITIAL_PORTFOLIO_MODAL__link2')}
        </Link>
        .
      </FirstParagraph>
      <SecondParagraph>{t('INITIAL_PORTFOLIO_MODAL__text3')}</SecondParagraph>
      <ThirdParagraph>{t('INITIAL_PORTFOLIO_MODAL__text4')}</ThirdParagraph>
      <Button
        onClick={() => {
          dispatch({
            type: 'closeModal',
          });
        }}
      >
        {t('INITIAL_PORTFOLIO_MODAL__button')}
      </Button>
    </>
  );
};

const Img = styled.img`
  margin: 0 auto;

  ${getUpMedia('md')} {
    max-width: 80%;
  }
`;

const Text = styled((props: TextProps) => (
  <DefText align={'center'} {...props} />
))`
  & + & {
    margin-bottom: 20px;
  }
`;

const Title = styled(Text)`
  margin-bottom: 20px;
  font-size: 30px;
`;

const FirstParagraph = styled(Text)`
  padding: 0 12px;
`;

const SecondParagraph = styled(Text)`
  padding: 0 12px;
`;

const ThirdParagraph = styled(Text)`
  padding: 0 22px;
`;

const Link = styled(DefLink)`
  && {
    ${semiBoldText};
  }
`;

const Button = styled(DefButton)`
  width: 200px;
  margin: 30px auto 0;
`;

export { InitialPortfolioModal };
