/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useCallback, useContext, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { getLocaleByAlpha2 } from 'country-locale-map';
import { useCreateNewMollieClientMutation } from '../apollo';
import { sendSentryError } from '../utils/sentry';
import { AlertContext } from '../providers';

export const useCreateMollieClient = () => {
  const { t, i18n } = useTranslation();
  const { setAlert } = useContext(AlertContext);
  const locale = getLocaleByAlpha2(i18n.language.toUpperCase());

  const showError = useCallback(
    (e: Error | ApolloError) => {
      setAlert({
        type: 'error',
        message: t('CREDIT_CARD__urlLinkErrorText'),
      });
      sendSentryError(e);
    },
    [setAlert, t]
  );

  const [createNewMollieClientMutation, data] =
    useCreateNewMollieClientMutation({
      fetchPolicy: 'no-cache',
      variables: {
        locale,
      },
      onError: (e) => {
        showError(e);
      },
    });

  const createNewMollieClient = useCallback(() => {
    createNewMollieClientMutation()
      .then(({ errors, data }) => {
        if (!isEmpty(errors) || !data) {
          throw new Error('There is not link in mutation response');
        }

        window.location.assign(data.newMollieClient.checkoutURL);
      })
      .catch((e) => {
        showError(e);
      });
  }, [createNewMollieClientMutation, showError]);

  return useMemo(
    () => [createNewMollieClient, data] as const,
    [createNewMollieClient, data]
  );
};
