/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {HTMLAttributes, useMemo} from 'react';
import styled, {css} from 'styled-components';
import {isString} from 'lodash-es';
import {useTranslation} from 'react-i18next';
import {useRendersCount} from 'react-use';
import {animated, useTransition} from '@react-spring/web';
import {boldText, titleTextFontFamily} from '../utils/fonts';
import moment from '../utils/moment';
import {useIsTablet} from '../hooks';
import {useUserTrialEndDateQuery} from '../apollo';
import {getColor, pxToRem} from '../styles';
import {useExchange, usePayments} from '../providers';
import clock from '../assets/images/Counter.svg';
import {Button as DefButton} from './buttons';
import {Text} from './texts';

export type TrialClockProps = {
  hidden: boolean;
} & HTMLAttributes<HTMLDivElement>;

const TrialClock = ({ hidden, ...props }: TrialClockProps) => {
  const { t } = useTranslation();
  const renderCount = useRendersCount();
  const tablet = useIsTablet();
  const { exchangeWizardVisible } = useExchange();
  const { paymentModalOpened, paymentTrialClockActive, togglePaymentModal } =
    usePayments();
  const { data: { getUser: user } = {} } = useUserTrialEndDateQuery();
  const trialEndDate = user?.trialEndDate;

  const visible = useMemo(
    () =>
      paymentTrialClockActive && !(exchangeWizardVisible || paymentModalOpened),
    [paymentTrialClockActive, exchangeWizardVisible, paymentModalOpened]
  );

  const transition = useTransition(visible, {
    from: { opacity: 0, transform: 'translateY(20%)', pointerEvents: 'none' },
    enter: () => async (next) => {
      if (!tablet && renderCount <= 2) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      await next({
        opacity: 1,
        transform: 'translateY(0)',
        pointerEvents: 'auto',
      });
    },
    leave: { opacity: 0, transform: 'translateY(-20%)', pointerEvents: 'none' },
  });

  if (!isString(trialEndDate) || hidden) return null;

  const leftDays = moment.utc(trialEndDate).isBefore(moment.utc())
    ? 0
    : moment.utc(trialEndDate).diff(moment.utc(), 'days') + 1;

  return (
    <>
      {transition(
        (style, visible, tt) =>
          visible && (
            <Wrapper key={tt.key} style={style as any} {...props}>
              <Header>
                <HeaderLeft>
                  <Title component={'h4'}>{t('TRIAL_CLOCK__title')}</Title>
                </HeaderLeft>
                <ClockWrapper>
                  <ClockImg src={clock} alt="Clock" />
                  <LeftDaysText $twoNumbers={leftDays > 9}>
                    {leftDays}
                  </LeftDaysText>
                </ClockWrapper>
              </Header>
              <Button
                buttonStyles={buttonStyles}
                onClick={() => togglePaymentModal(true)}
              >
                {t('TRIAL_CLOCK__buttonText')}
              </Button>
            </Wrapper>
          )
      )}
    </>
  );
};

const Wrapper = styled(animated.div)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.4);
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 10px 14px;
`;

const HeaderLeft = styled.div`
  margin-right: 10px;
`;

const Title = styled(Text)`
  font-family: ${titleTextFontFamily};
  padding: 4px 0;
  ${boldText};
  font-size: ${pxToRem(22)};
  line-height: 1;
`;

const ClockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 114px;
  height: 60px;
  margin-left: auto;
  position: relative;
`;

const ClockImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 1px;
`;

const LeftDaysText = styled(Text)<{
  $twoNumbers?: boolean;
}>`
  position: relative;
  right: ${({ $twoNumbers }) => ($twoNumbers ? 1 : 3)}px;
  margin-top: 10px;
  font-size: 42px;
  ${boldText};
  line-height: 1;
  letter-spacing: -1.2px;
  font-family: ${titleTextFontFamily};
  color: #fff;
`;

const Button = styled(DefButton)`
  width: 100%;
`;

const buttonStyles = css`
  padding: 18px 18px;
  font-size: 1.1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  &:hover {
    color: ${getColor('skyBlue')};
  }
`;

export { TrialClock };
