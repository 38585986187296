/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {isFunction} from 'lodash-es';
import {Paper} from '@mui/material';
import {ModalCloseButton, ModalCloseButtonProps} from './ModalCloseButton';

export type ContentModalWhiteContentProps = {
  closeButtonProps?: Partial<ModalCloseButtonProps>;
  close?: () => void;
};

const ContentModalWhiteContent: FC<
  PropsWithChildren<ContentModalWhiteContentProps>
> = ({ children, closeButtonProps, close, ...props }) => (
  <Wrapper {...props}>
    {isFunction(close) && (
      <ModalCloseButton {...closeButtonProps} onClick={close} />
    )}
    {children}
  </Wrapper>
);

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 500px;
  padding: 40px;
  margin: auto;
  position: relative;
  border-radius: 3px;
  box-shadow: 0 0 11px 0 rgba(63, 73, 105, 0.05);
  

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: auto;
    max-width: 500px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    box-shadow: none;
  }
`;

export { ContentModalWhiteContent };
