/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled, { css } from 'styled-components';
import { omit } from 'lodash-es';
import {
  FormControl as DefFormControl,
  FormControlLabel as DefFormControlLabel,
  FormHelperText as DefFormHelperText,
  FormControlLabelProps,
} from '@mui/material';
import { Checkbox } from './Checkbox';

export type CheckboxWithLabelProps = Omit<FormControlLabelProps, 'control'> & {
  error?: boolean;
  helperText?: string;
};

const CheckboxWithLabel = ({
  className,
  helperText,
  ...props
}: CheckboxWithLabelProps) => (
  <FormControl className={className} error={props.error}>
    <FormControlLabel
      {...omit(props, ['error'])}
      control={<Checkbox value={props.name} checked={props.value as boolean} />}
      aria-describedby={props.id}
    />
    {!!helperText && (
      <FormHelperText id={props.id}>{helperText}</FormHelperText>
    )}
  </FormControl>
);

const FormControl = styled(DefFormControl)`
  ${({ error, theme }) =>
    error &&
    css`
      .MuiFormControlLabel-label {
        color: ${theme.palette.error.main};
      }
    `}
`;

const FormControlLabel = styled(DefFormControlLabel)`
  margin-left: 0;
  align-items: flex-start;

  .MuiCheckbox-root {
    flex: 0 0 auto;
    margin-right: 6px;
    position: relative;
    top: -3px;
  }

  .MuiFormControlLabel-label {
    line-height: 1.3;
  }
`;

const FormHelperText = styled(DefFormHelperText)`
  margin-left: 28px;
  margin-top: 2px;
`;

export { CheckboxWithLabel };
