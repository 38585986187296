/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

const FacebookIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path d="M12.054 32h5.996l.004-16 5.446-.002.584-5.514H18.05l.008-2.76c0-1.438.098-2.208 2.162-2.208h3.812V0h-5.466c-5.298 0-6.518 2.738-6.518 7.236l.006 3.248-4.022.002V16h4.022v16z" />
  </Icon>
);

export { FacebookIcon };
