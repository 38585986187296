/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useMemo } from 'react';
import { isString } from 'lodash-es';
import { useField, useFormikContext } from 'formik';
import { Input, PasswordField, InputProps } from '../form-elements';

export type FormikInputProps = Omit<InputProps, 'onChange'> & {
  name: string;
};

const FormikInput = ({ name, ...props }: FormikInputProps) => {
  const { setFieldValue } = useFormikContext();
  const [{ value, onChange, ...field }, { touched, error }] = useField(name);

  const Component = useMemo(
    () => (props.type === 'password' ? PasswordField : Input),
    [props.type]
  );

  return (
    <Component
      {...field}
      value={props.select && value === 0 ? '' : value}
      onChange={(newValue) => {
        isString(newValue) ? setFieldValue(name, newValue) : onChange(newValue);
      }}
      {...props}
      {...(touched && !!error
        ? {
            error: true,
            helperText: error,
          }
        : {})}
    />
  );
};

export { FormikInput };
