/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import iconsManifest from 'cryptocurrency-icons/manifest.json';
import { checkFileExistence } from './check-file-existence';

export const getCryptoIconPath = async (rawSymbol: string) => {
  const symbol = rawSymbol.toLowerCase();

  const iconData = iconsManifest.find(
    ({ symbol: manifestSymbol }) =>
      manifestSymbol.toLowerCase() === symbol.toLowerCase()
  );

  if (iconData) {
    const iconName = iconData.symbol.toLowerCase();

    try {
      return (await import(`cryptocurrency-icons/svg/color/${iconName}.svg`))
        .default;
    } catch (e) {
      return (await import(`cryptocurrency-icons/svg/color/generic.svg`))
        .default;
    }
  }

  const defFolderSymbolUrl =
    process.env.PUBLIC_URL + `/assets/img/icons/${symbol}`;

  const imageInDefFolder = (
    await Promise.all(
      ['svg', 'png', 'jpg'].map((ext) =>
        checkFileExistence(`${defFolderSymbolUrl}.${ext}`)
      )
    )
  ).find((value) => !!value);

  if (imageInDefFolder) return imageInDefFolder;

  return (await import(`cryptocurrency-icons/svg/color/generic.svg`)).default;
};
