/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import styled from 'styled-components';
import { Link as DefLink, LinkProps as DefLinkProps } from '@mui/material';
import { DoubleRightIcon as DefDoubleRightIcon } from '../icons';

export type LinkProps = DefLinkProps & {
  withDoubleLeftArrow?: boolean;
  withDoubleRightArrow?: boolean;
};

const Link = ({
  children,
  withDoubleLeftArrow = false,
  withDoubleRightArrow = false,
  ...props
}: LinkProps) => (
  <StyledLink {...props}>
    {withDoubleLeftArrow && (
      <>
        <DoubleLeftIcon />{' '}
      </>
    )}
    {children}
    {withDoubleRightArrow && (
      <>
        {' '}
        <DoubleRightIcon />
      </>
    )}
  </StyledLink>
);

const DoubleRightIcon = styled(DefDoubleRightIcon)``;

const DoubleLeftIcon = styled(DoubleRightIcon)`
  transform: rotate(180deg);
`;

const StyledLink = styled(DefLink)`
  ${DoubleRightIcon} {
    width: 14px;
    height: 14px;
    color: inherit;
    position: relative;
    left: -1px;
    bottom: -3px;
  }

  ${DoubleLeftIcon} {
    left: 0;
  }
`;

export { Link };
