/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useMemo } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Formik, Form, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormikInput } from '../formik-elements';
import { Button as DefButton } from '../buttons';

type Props = {
  className?: string;
  onCodeFill: (arg0: any) => Promise<void>;
};

const TwoFactorBackupCodeForm = ({ className, onCodeFill }: Props) => {
  const { t } = useTranslation();

  const schema = useMemo(() => getTwoFactorBackupCodeFormSchema(t), [t]);

  return (
    <Formik
      initialValues={{
        code: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, { setSubmitting }) => {
        await onCodeFill(values.code);
        setSubmitting(false);
      }}
    >
      {({
        values,
        handleSubmit,
        isSubmitting,
      }: FormikProps<TwoFactorBackupCodeFormValues>) => (
        <FormWrapper className={className} onSubmit={handleSubmit}>
          {Object.entries(values).map(([name]) => (
            <FieldInput
              key={name}
              id={`backup-code-2fa-form-${name}`}
              name={name}
              label={t('TWO_FACTOR_BACKUP_CODE_FORM__inputPlaceholder')}
              disabled={isSubmitting}
            />
          ))}

          <BottomBlock>
            <Button
              type={'submit'}
              variant={'contained'}
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('TWO_FACTOR_BACKUP_CODE_FORM__buttonText')}
            </Button>
          </BottomBlock>
        </FormWrapper>
      )}
    </Formik>
  );
};

type TwoFactorBackupCodeFormValues = { code: string };

const getTwoFactorBackupCodeFormSchema = (
  t: TFunction
): Yup.SchemaOf<TwoFactorBackupCodeFormValues> =>
  Yup.object().shape({
    code: Yup.string()
      .length(10, t('TWO_FACTOR_BACKUP_CODE_FORM__wrongCodeLength'))
      .required(t('COMMON_FORMS_ERROR__required')),
  });

const FormWrapper: React.FC<
  { className: string | void } & React.FormHTMLAttributes<HTMLFormElement>
> = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FieldInput = styled(FormikInput)`
  flex: 0 0 100%;
`;

const BottomBlock = styled.div`
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
`;

const Button = styled(DefButton)`
  width: 100%;
`;

export { TwoFactorBackupCodeForm };
