/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useGoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { setAuthVar } from '../../utils/apollo-vars';
import { removeToken } from '../../utils/auth';
import { removeAuthIdentityId } from '../../utils/auth-identity-id';
import { wait } from '../../utils/helpers';
import { Spinner } from '../../styled';
import { paths } from '../paths';

const LogoutPage = () => {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { loaded: googleLoaded, signOut: googleSignOut } = useGoogleLogout({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
    onLogoutSuccess: () => undefined,
    onFailure: () => undefined,
  });

  const logout = useCallback(() => {
    wait()
      .then(() => {
        googleSignOut();
        removeToken();
        removeAuthIdentityId();
        return client.resetStore();
      })
      .then(() => setAuthVar(false))
      .then(() => {
        navigate(paths.login);
      });
  }, [client, googleSignOut, navigate]);

  useEffect(() => {
    const timer = setTimeout(logout, googleLoaded ? 0 : 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [googleLoaded, logout]);

  return (
    <Wrapper>
      <Spinner />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export { LogoutPage };
