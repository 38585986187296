/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { colors, ColorValue } from '../../utils/colors';

const Wrapper = styled.svg<{ fillColor: ColorValue }>`
  width: 20px;
  height: 20px;
  fill: ${({ fillColor }) => fillColor};
`;

type Props = {
  className?: string;
  fillColor?: ColorValue;
};

const QuestionMark = ({ className, fillColor = colors.blue }: Props) => (
  <Wrapper
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30 30"
    fillColor={fillColor}
  >
    <path d="M15 0a14.6 14.6 0 0 1 10.6 4.4A14.6 14.6 0 0 1 30 15a14.6 14.6 0 0 1-4.4 10.6A14.6 14.6 0 0 1 15 30a14.6 14.6 0 0 1-10.6-4.4A14.6 14.6 0 0 1 0 15 14.6 14.6 0 0 1 4.4 4.4 14.6 14.6 0 0 1 15 0zm-1.8 25.5a2.4 2.4 0 0 0 1.8.7 2.4 2.4 0 0 0 2.5-2.5 2.3 2.3 0 0 0-.7-1.7 2.4 2.4 0 0 0-1.8-.7 2.4 2.4 0 0 0-1.8.7 2.3 2.3 0 0 0-.7 1.7 2.4 2.4 0 0 0 .7 1.8zm3.7-8a1.1 1.1 0 0 1 .5-.9l1.2-.9 1.5-1a5.3 5.3 0 0 0 1.3-1.6 6.2 6.2 0 0 0 .5-2.5 5.9 5.9 0 0 0-2-4.5A7.5 7.5 0 0 0 15 4.4a6.1 6.1 0 0 0-3.2.8 7.6 7.6 0 0 0-2.3 1.5l-.8.8 2.5 2.5.6-.5 1.4-.9a4.1 4.1 0 0 1 1.8-.5 3.3 3.3 0 0 1 2.2.8 2.2 2.2 0 0 1 .9 1.7 1.7 1.7 0 0 1-.8 1.5 6.6 6.6 0 0 1-1.7 1.2 5.5 5.5 0 0 0-1.7 1.4 3.3 3.3 0 0 0-.8 2.2v1.2h3.8z" />
  </Wrapper>
);

export { QuestionMark };
