/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { Icon as DefIcon, IconProps } from './Icon';

const CloseIcon = (props: IconProps) => (
  <Icon viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="square"
    >
      <path d="M1.538 1.538l12.924 12.924M14.462 1.538L1.538 14.462" />
    </g>
  </Icon>
);

const Icon = styled(DefIcon)`
  color: rgba(0, 0, 0, 0.54);
`;

export { CloseIcon };
