/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { commonNumbersAligning } from './common';
import { TableCell } from './TableCell';

export const PercentageCell = styled(TableCell)`
  ${commonNumbersAligning};
  min-width: 75px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 55px;
    padding-right: 6px;
  }
`;
