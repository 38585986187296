/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled, { css } from 'styled-components';
import { TableRow as DefTableRow } from '@mui/material';
import { colors } from '../../utils/colors';
import { getColor } from '../../styles';
import { NameCell } from './NameCell';

const tableRowHoverStyles = css`
  background-color: #a9d5fd;

  ${NameCell} {
    background-color: #a9d5fd;
  }
`;

export const TableRow = styled(DefTableRow)<{
  $lockedFiat: boolean;
  $hover: boolean;
}>`
  &:nth-of-type(even) {
    background-color: ${colors.lightBlue};

    ${NameCell} {
      background-color: ${colors.lightBlue};
    }
  }

  &.MuiTableRow-hover:hover {
    ${tableRowHoverStyles};
  }

  ${({ $hover }) =>
    $hover &&
    css`
      && {
        ${tableRowHoverStyles};
      }
    `}

  ${({ $lockedFiat }) =>
    $lockedFiat &&
    css`
      .MuiTableCell-root.MuiTableCell-root {
        border-top: 1px solid ${getColor('flamingo')};
        border-bottom: 1px solid ${getColor('flamingo')};
        &:first-child {
          border-left: 1px solid ${getColor('flamingo')};
        }
        &:last-child {
          border-right: 1px solid ${getColor('flamingo')};
        }
      }
    `};
`;
