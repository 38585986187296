/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import {
  CustomUserStatus,
  ExchangeCode,
  KeysStatus,
  InvoicesFilterByStatus
} from '../apollo';

export const customUserStatusesTranslations: {
  [key in CustomUserStatus]?: string;
} = {
  [CustomUserStatus.NotVerified]: 'CUSTOM_USER_STATUS__notVerified',
  [CustomUserStatus.SignedUp]: 'CUSTOM_USER_STATUS__signedUp',
  [CustomUserStatus.NoKey]: 'CUSTOM_USER_STATUS__noKey',
  [CustomUserStatus.NoFunds]: 'CUSTOM_USER_STATUS__noFunds',
  [CustomUserStatus.Trial]: 'CUSTOM_USER_STATUS__trial',
  [CustomUserStatus.NoPaymentDetails]: 'CUSTOM_USER_STATUS__noPaymentDetails',
  [CustomUserStatus.Active]: 'CUSTOM_USER_STATUS__active',
  [CustomUserStatus.OpenInvoice]: 'CUSTOM_USER_STATUS__openInvoice',
  [CustomUserStatus.Cancelled]: 'CUSTOM_USER_STATUS__cancelled',
  [CustomUserStatus.Removed]: 'CUSTOM_USER_STATUS__removed',
};

export const exchangesCodesTranslations: {
  [key in ExchangeCode]: string;
} = {
  [ExchangeCode.Bitvavo]: 'EXCHANGES__bitvavo',
  [ExchangeCode.Kraken]: 'EXCHANGES__kraken',
};

export const exchangesKeysTranslations: {
  [key in KeysStatus]: string;
} = {
  [KeysStatus.NoKeys]: 'EXCHANGES_KEYS__noKeys',
  [KeysStatus.InvalidKeys]: 'EXCHANGES_KEYS__invalidKeys',
  [KeysStatus.NoEnoughFunds]: 'EXCHANGES_KEYS__noEnoughFunds',
  [KeysStatus.Ok]: 'EXCHANGES_KEYS__ok',
};

export const invoiceStatusesTranslations: {
  [key in InvoicesFilterByStatus]: string;
} = {
  [InvoicesFilterByStatus.Submitted]: 'INVOICE_STATUS__submitted',
  [InvoicesFilterByStatus.Pay]: 'INVOICE_STATUS__pay',
  [InvoicesFilterByStatus.Paid]: 'INVOICE_STATUS__paid',
};
