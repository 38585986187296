/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { colors } from 'utils/colors';
import { Button, ButtonProps } from './Button';

export const SmallButton = styled((props: ButtonProps) => (
  <Button color={'primary'} size={'small'} {...props} />
))`
  width: 126px;
  color: ${colors.white};
`;
