/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, {HTMLAttributes} from 'react';
import styled from 'styled-components';
import {getDownMedia} from '../styles';
import {
  ContentModalWhiteContent as DefDashboardModalWhiteContent,
  ContentModalWrapper as DefDashboardModalWrapper,
} from './modal-elements';
import {InvalidProfileForm} from './forms';

export type InvalidProfileProps = HTMLAttributes<HTMLDivElement>;

const InvalidProfile = (props: InvalidProfileProps) => (
  <DashboardModalWrapper opened>
    <DashboardModalWhiteContent {...props}>
      <InvalidProfileForm />
    </DashboardModalWhiteContent>
  </DashboardModalWrapper>
);

const DashboardModalWrapper = styled(DefDashboardModalWrapper)`

  ${getDownMedia('sm')} {
    background-color: #fff;
  }
`;

const DashboardModalWhiteContent = styled(DefDashboardModalWhiteContent)`
  padding-top: 52px;
`;

export { InvalidProfile };
