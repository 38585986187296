/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { usePortfolioCurrency } from '../../providers';
import { getPercentages } from '../../utils/percentages';
import { getPriceDifference } from '../../utils/currency';
import { PortfolioCurrency } from '../../apollo';
import {
  BlockData,
  BlockIcon,
  GraphIconSummary,
  MobileBlock,
  MobileBlockContent,
  MobileBlockTitle,
  PieIcon,
} from './styled';

export type Change24MobileProps = {
  data: {
    change24Percent: number;
    change24Amount: number;
  };
} & HTMLAttributes<HTMLLIElement>;

const Change24Mobile = ({
  data: { change24Percent, change24Amount },
  ...props
}: Change24MobileProps) => {
  const { t } = useTranslation();
  const { currency } = usePortfolioCurrency();

  const positiveAmount = change24Amount !== 0 ? change24Amount > 0 : undefined;

  const positivePercent =
    change24Percent !== 0 ? change24Percent > 0 : undefined;

  return (
    <MobileBlock {...props}>
      <MobileBlockTitle variant={'h3'}>
        {t('DASHBOARD_PAGE__summaryChangeValue')}
      </MobileBlockTitle>
      <MobileBlockContent>
        <BlockData positiveValue={positivePercent}>
          {getPercentages(change24Percent, { withSign: true })}
        </BlockData>
        <BlockIcon
          style={{
            marginRight: 20,
          }}
        >
          <GraphIconSummary />
        </BlockIcon>
        <BlockData positiveValue={positiveAmount}>
          {getPriceDifference(
            change24Amount,
            currency !== PortfolioCurrency.Eur
              ? {
                  currency: 'USD',
                }
              : undefined
          )}
        </BlockData>
        <BlockIcon>
          <PieIcon />
        </BlockIcon>
      </MobileBlockContent>
    </MobileBlock>
  );
};

export { Change24Mobile };
