/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useMemo } from 'react';
import { getNames } from 'i18n-iso-countries';
import { MenuItem } from '@mui/material';
import { Input, InputProps } from './Input';

export type CountriesSelectProps = InputProps;

const CountriesSelect = ({ type, ...props }: CountriesSelectProps) => {
  const countriesData = useMemo(() => getCountriesData(), []);

  return (
    <Input select {...props}>
      {countriesData.map(([code, name]) => (
        <MenuItem key={name} value={code}>
          {name}
        </MenuItem>
      ))}
    </Input>
  );
};

type CountriesData = [string, string];

const getCountriesData: () => Array<CountriesData> = () =>
  Object.entries(getNames('nl', { select: 'official' }))
    .filter(([code]) => code !== 'US')
    .sort(([, firstName], [, secondName]) =>
      firstName.toUpperCase() > secondName.toUpperCase() ? 1 : -1
    );

export { CountriesSelect };
