/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { CircularProgress } from '@mui/material';

export const Spinner = styled(CircularProgress)`
  margin: ${({ theme }) => theme.spacing(2)}px;
`;
