/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { FC, PropsWithChildren } from 'react';
import { AlertWrapper } from './Alert';
import { AlertModals } from './AlertModals';
import { ApolloWrapper } from './Apollo';
import { Auth } from './Auth';
import { GoogleRecaptcha } from './GoogleRecaptcha';
import { GoogleRecaptchaV3 } from './GoogleRecaptchaV3';
import { Modals } from './Modals';
import { Pickers } from './PickersProvider';
import { Reminder2FA } from './Reminder2FA';
import { Router } from './Router';
import { Styles } from './Styles';

const RootProviders: FC<PropsWithChildren> = ({ children }) => (
  <Styles>
    <ApolloWrapper>
      <Router>
        <AlertWrapper>
          <GoogleRecaptchaV3>
            <GoogleRecaptcha>
              <AlertModals>
                <Modals>
                  <Reminder2FA>
                    <Auth>
                      <Pickers>{children}</Pickers>
                    </Auth>
                  </Reminder2FA>
                </Modals>
              </AlertModals>
            </GoogleRecaptcha>
          </GoogleRecaptchaV3>
        </AlertWrapper>
      </Router>
    </ApolloWrapper>
  </Styles>
);

export { RootProviders };
