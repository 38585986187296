/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BottomBlockText, BottomBlockTitle } from './dashboard-page';

const LiquidationDesc = (props: HTMLAttributes<HTMLDivElement>) => {
  const { t } = useTranslation();

  return (
    <Wrapper {...props}>
      <BottomBlockTitle>{t('LIQUIDATION_DESC__title')}</BottomBlockTitle>
      <BottomBlockText>{t('LIQUIDATION_DESC__text1')}</BottomBlockText>
      <BottomBlockText>{t('LIQUIDATION_DESC__text2')}</BottomBlockText>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export { LiquidationDesc };
