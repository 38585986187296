/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import React, { HTMLAttributes, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { isEmpty, isString } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { ApolloError } from '@apollo/client';
import {
  Table as DefTable,
  TableBody,
  TableCell as DefTableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { tablesData } from '../../utils/consts';
import { getPrice } from '../../utils/currency';
import { semiBoldText } from '../../utils/fonts';
import moment from '../../utils/moment';
import { sendSentryError } from '../../utils/sentry';
import { useIsTablet } from '../../hooks';
import {
  useInvoicesQuery,
  useDirectMolliePaymentMutation,
  InvoicePaymentStatus,
  InvoicesQueryHookResult,
} from '../../apollo';
import { useAlert } from '../../providers';
import { Spinner as DefSpinner } from '../../styled';
import { SmallButton as Button } from '../buttons';
import { DownloadFileLink as DefDownloadFileLink } from '../texts';

const InvoicesTable = (props: HTMLAttributes<HTMLTableElement>) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const isTablet = useIsTablet();
  const columnNamesByApiName = useMemo(() => getColumnNamesByApiName(t), [t]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    tablesData.invoices.initialRows
  );
  const { loading, error, data } = useInvoicesQuery({
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });

  const handlePaymentLinkError = (e: Error | ApolloError) => {
    setAlert({
      type: 'error',
      message: t('INVOICES_PAGE__errorGettingDirectMollieLink'),
    });
    sendSentryError(e);
  };

  const [getDirectPaymentLink, { loading: loadingDirectPaymentLink }] =
    useDirectMolliePaymentMutation({
      onCompleted: ({ directMolliePayment: { checkoutURL } }) => {
        if (!checkoutURL) {
          return handlePaymentLinkError(
            new Error(`Couldn't get directMolliePayment link`)
          );
        }

        window.location.replace(checkoutURL);
      },
      onError: handlePaymentLinkError,
    });

  if (loading && isEmpty(data)) return <Spinner />;

  if (error) {
    return <span>{t('INVOICES_PAGE__loadError')}</span>;
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rawInvoices = data?.getInvoices ?? [];

  if (isEmpty(rawInvoices)) {
    return <span>{t('INVOICES_PAGE__noInvoices')}</span>;
  }

  const invoices = rawInvoices
    .map(addSubscriptionFieldToInvoice)
    .map(modifyInvoiceFieldValues);

  return (
    <Table {...props}>
      <TableHead>
        <TableRow>
          {(isTablet ? mobileColumns : desktopColumns).map(
            (columnName, index) => (
              <TableCell key={index}>
                {columnNamesByApiName[columnName]}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {invoices
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((invoice, index) => (
            <TableRow key={index}>
              {(isTablet ? mobileColumns : desktopColumns).map((key, index) => {
                const value = invoice[key];
                let cellValue: ReactNode = '';

                switch (key) {
                  case 'status':
                    if (value === InvoicePaymentStatus.InvoiceStatusUnpaid) {
                      cellValue = t('INVOICES_PAGE__tableUnpaidStatus');
                    }

                    if (value === InvoicePaymentStatus.InvoiceStatusSubmitted) {
                      cellValue = t('INVOICES_PAGE__tableSubmittedStatus');
                    }

                    if (
                      [
                        InvoicePaymentStatus.InvoiceStatusFailedOnce,
                        InvoicePaymentStatus.InvoiceStatusFailedTwice,
                      ].some((st) => st === value)
                    ) {
                      cellValue = (
                        <Button
                          loading={loadingDirectPaymentLink}
                          onClick={() => {
                            getDirectPaymentLink({
                              variables: {
                                id: Number(invoice.id),
                              },
                            });
                          }}
                        >
                          {t('INVOICES_PAGE__tablePayButton')}
                        </Button>
                      );
                    }

                    if (
                      [
                        InvoicePaymentStatus.InvoiceStatusCharged,
                        InvoicePaymentStatus.InvoiceStatusComplete,
                      ].some((st) => st === value)
                    ) {
                      cellValue = t('INVOICES_PAGE__tablePaidStatus');
                    }

                    break;
                  case 'url':
                    cellValue = isString(value) && (
                      <DownloadFileLink href={value}>
                        {t('INVOICES_PAGE__tableDownloadButtonText')}
                      </DownloadFileLink>
                    );
                    break;
                  default:
                    cellValue = value;
                }

                return <TableCell key={index}>{cellValue}</TableCell>;
              })}
            </TableRow>
          ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={tablesData.invoices.rowsPerPageOptions}
            colSpan={8}
            count={invoices.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            labelRowsPerPage={`${t('INVOICES_PAGE__tableRowsPerPage')}:`}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

type InvoiceResponse = NonNullable<
  InvoicesQueryHookResult['data']
>['getInvoices'][number];

type Invoice = InvoiceResponse & {
  subscription: string;
};

type InvoiceKey = keyof Invoice;

const mobileColumns: InvoiceKey[] = [
  'id',
  'status',
  'dueDate',
  'subscription',
  'price',
  'vat',
  'total',
  'url',
];

const desktopColumns: InvoiceKey[] = [
  'id',
  'dueDate',
  'subscription',
  'price',
  'vat',
  'total',
  'status',
  'url',
];

const getColumnNamesByApiName: (t: TFunction) => {
  [key in keyof Invoice]: string;
} = (t) => ({
  id: t('INVOICES_PAGE__tableIDColumnName'),
  dueDate: t('INVOICES_PAGE__tableDateColumnName'),
  subscription: t('INVOICES_PAGE__tableSubscriptionColumnName'),
  price: t('INVOICES_PAGE__tablePriceColumnName'),
  vat: t('INVOICES_PAGE__tableVATColumnName'),
  total: t('INVOICES_PAGE__tableTotalColumnName'),
  status: t('INVOICES_PAGE__tableStatusColumnName'),
  url: '',
});

const addSubscriptionFieldToInvoice = (invoice: InvoiceResponse) =>
  Object.entries(invoice).reduce<Invoice>(
    (res, [key, value], index) => ({
      ...res,
      ...(index === 1
        ? {
            subscription: 'MR Indexstrategie',
            [key]: value,
          }
        : { [key]: value }),
    }),
    {} as Invoice
  );

const modifyInvoiceFieldValues = (invoice: Invoice) =>
  (
    Object.entries(invoice) as Array<[keyof Invoice, Invoice[keyof Invoice]]>
  ).reduce<Invoice>((res, [key, rawValue]) => {
    let value;

    switch (key) {
      case 'dueDate':
        value = moment.utc(rawValue as string).format('DD-MM-YY');
        break;
      case 'price':
      case 'total':
        value = getPrice(rawValue as number);
        break;
      case 'vat':
        value = getPrice(rawValue as number);
        break;
      default:
        value = rawValue;
    }

    return {
      ...res,
      [key]: value,
    };
  }, {} as Invoice);

const Table = styled(DefTable)`
  .MuiTableCell-root {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    border-bottom: none;

    &:last-child {
      width: 9%;
      min-width: 80px;
    }
  }

  .MuiTableCell-head {
    padding-bottom: 22px;
    ${semiBoldText};
  }

  .MuiTableCell-footer {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const TableCell = styled(DefTableCell)``;

const Spinner = styled(DefSpinner)`
  margin: 0 auto;
`;

const DownloadFileLink = styled(DefDownloadFileLink)`
  display: block;
  text-align: center;
`;

export { InvoicesTable };
