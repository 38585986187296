/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { getDownMedia } from '../../styles';

export const WideContent = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 770px;
  margin: auto;

  ${getDownMedia('lg')} {
    width: 100%;
  }

  ${getDownMedia('md')} {
    width: 80%;
    max-width: none;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 32px;
  }

  ${getDownMedia('sm')} {
    width: 100%;
    margin-bottom: 0;
  }
`;
