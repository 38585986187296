/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

const DoubleRightIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path
      d="M23.7 15.3l-5.9-6.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.3 6-5.3 6c-.4.4-.4 1 0 1.4s1 .4 1.4 0l5.9-6.7c.2-.2.3-.5.3-.7 0-.2-.1-.5-.3-.7zM9.2 23.5c.4.4 1 .4 1.4 0l5.9-6.7c.2-.2.3-.5.3-.7 0-.3-.1-.5-.3-.7l-5.9-6.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l5.3 6-5.3 6c-.4.3-.4 1 0 1.4z"
      fill="currentColor"
      stroke="#0a355a"
      strokeWidth={0.25}
      strokeMiterlimit={10}
    />
  </Icon>
);

export { DoubleRightIcon };
