/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { omit } from 'lodash-es';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { getColor, getDownMedia } from '../../styles';
import { CheckboxWithLabel as DefCheckbox } from '../form-elements';
import {
  ContentModalWhiteContent,
  ContentModalWhiteContentProps,
} from '../modal-elements';
import { Button, ButtonProps } from '../buttons';
import descImg from '../../assets/images/_Payment_icons_-_Block.svg';

export type CreditCardBlockProps = ContentModalWhiteContentProps & {
  buttonProps: ButtonProps;
};

const CreditCardBlock = ({ buttonProps, ...props }: CreditCardBlockProps) => {
  const { t } = useTranslation();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    termsAccepted && setShowError(false);
  }, [termsAccepted]);

  return (
    <Wrapper
      closeButtonProps={{
        cssProps: closeButtonCss,
      }}
      {...props}
    >
      <Section>
        <Title variant={'h3'}>{t('CREDIT_CARD_CARD_BLOCK__title1')}</Title>
        <Text>{t('CREDIT_CARD_CARD_BLOCK__text1')}</Text>
      </Section>
      <Section>
        <Title variant={'h3'}>{t('CREDIT_CARD_CARD_BLOCK__title2')}</Title>
        <Text>{t('CREDIT_CARD_CARD_BLOCK__text2')}</Text>
        <Checkbox
          style={{
            marginTop: 14,
            marginBottom: 20,
          }}
          name={'accepted-conditions'}
          value={termsAccepted}
          label={
            <>
              {t('CREDIT_CARD_CARD_BLOCK__checkbox1text')}{' '}
              <Link href={SiteData.TERMS_LINK} {...externalLinkProps}>
                {t('CREDIT_CARD_CARD_BLOCK__checkbox1link')}
              </Link>{' '}
              {t('CREDIT_CARD_CARD_BLOCK__checkbox2text')}{' '}
              <Link href={SiteData.PRIVACY_LINK} {...externalLinkProps}>
                {t('CREDIT_CARD_CARD_BLOCK__checkbox2link')}
              </Link>{' '}
              {t('CREDIT_CARD_CARD_BLOCK__checkbox3text')}
            </>
          }
          error={showError}
          helperText={
            showError
              ? t('CREDIT_CARD_CARD_BLOCK__checkboxErrorText')
              : undefined
          }
          onChange={(_, checked) => setTermsAccepted(checked)}
        />
        <Button
          {...omit(buttonProps, 'onClick')}
          onClick={(event) => {
            if (termsAccepted) {
              setShowError(false);
              buttonProps.onClick?.(event);
            } else {
              setShowError(true);
            }
          }}
        >
          {t('CREDIT_CARD_CARD_BLOCK__buttonText')}
        </Button>
        <DescImage src={descImg} alt={'Payment options'} />
      </Section>
    </Wrapper>
  );
};

const Wrapper = styled(ContentModalWhiteContent)`
  max-width: none;
  flex: 0 0 350px;
  padding: 28px 20px 30px;
  margin: 0 0 0 50px;

  ${getDownMedia('md')} {
    flex-basis: auto;
    width: auto;
    margin-top: 20px;
    margin-left: 0;
    position: static;
  }
`;

const closeButtonCss = css`
  ${getDownMedia('md')} {
    top: 74px;
    right: 70px;
  }

  ${getDownMedia('sm')} {
    top: 18px;
    right: 16px;
  }
`;

const Section = styled.section`
  & + & {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${getColor('lightGray')};
  }
`;

const Title = styled(Typography)`
  margin-bottom: 4px;
`;

const Text = styled(Typography)``;

const DescImage = styled.img`
  width: auto;
  height: auto;
  margin: 20px auto 0;
`;

const Checkbox = styled(DefCheckbox)`
  .MuiFormControlLabel-label {
    font-size: 0.9rem;
  }

  .MuiFormHelperText-root {
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

export { CreditCardBlock };
