/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { toNumber } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Form as DefForm, Formik, FormikFormProps, FormikProps } from 'formik';
import { FileCopyOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, Link } from '@mui/material';
import { externalLinkProps, SiteData } from '../../utils/consts';
import { getClipboardText } from '../../utils/helpers';
import { sendSentryError } from '../../utils/sentry';
import { getExchangeKeysFormSchema } from '../../utils/validation-schemas';
import { useAlertModal } from '../../hooks';
import {
  ExchangeCode,
  WizardStatusType,
  InitialRebalanceTimeFragmentDoc,
  useAddExchangeKeysMutation,
} from '../../apollo';
import { getColor } from '../../styles';
import { useAlert, useExchange } from '../../providers';
import { bottomButtonStyles } from '../../styles/dashboard-page';
import { Button } from '../buttons';
import { BottomBlockText, BottomBlockTitle } from '../dashboard-page';
import { CheckboxWithLabel, Input as DefInput } from '../form-elements';

const ExchangeKeysForm = (props: FormikFormProps) => {
  const { t } = useTranslation();
  const { setAlert } = useAlert();
  const { exchange } = useExchange();
  const { dispatch: dispatchAlertModal } = useAlertModal();

  const [acceptedConditions, setAcceptedConditions] = useState(false);

  const schema = useMemo(() => getExchangeKeysFormSchema(t), [t]);

  const { translations, descLink } = useMemo(() => {
    if (!exchange) {
      return {
        translations: {
          key: { label: '', placeholder: '' },
          secretKey: { label: '', placeholder: '' },
          conditions: {
            label: '',
          },
        },
        descLink: '',
      };
    }

    return {
      translations: translationsByFieldName[exchange.exchangeCode],
      descLink: conditionsLinks[exchange.exchangeCode],
    };
  }, [exchange]);

  const showAddExchangeKeysError = () => {
    setAlert({
      type: 'error',
      message: t('EXCHANGE_KEYS_FORM__errorText'),
    });
  };

  const [addKeys] = useAddExchangeKeysMutation({
    update: (cache, { data }) => {
      const exchange = data?.addKeys;

      exchange &&
        cache.writeFragment({
          id: cache.identify(exchange),
          fragment: InitialRebalanceTimeFragmentDoc,
          broadcast: false,
          data: {
            initialRebalanceTime: null,
          },
        });
    },
    onCompleted: ({ addKeys: { wizardStatus } }) => {
      if (wizardStatus !== WizardStatusType.NoKeys) {
        dispatchAlertModal(['setModalContent', 'updateExchangeKeysSuccess']);
      } else {
        showAddExchangeKeysError();
      }
    },
    onError: (error) => {
      showAddExchangeKeysError();
      sendSentryError(error);
    },
  });

  return (
    <Formik
      initialValues={{
        key: '',
        secretKey: '',
      }}
      validationSchema={schema}
      onSubmit={async (values, { resetForm }) => {
        exchange?.exchangeID &&
          (await addKeys({
            variables: {
              exchangeId: toNumber(exchange.exchangeID),
              ...values,
            },
          }));
        resetForm();
        setAcceptedConditions(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        handleChange,
      }: FormikProps<ExchangeKeysFormValues>) => (
        <Form {...props}>
          <BottomBlockTitle>{t('EXCHANGE_KEYS_FORM__title')}</BottomBlockTitle>
          <Desc>
            <Link href={descLink} {...externalLinkProps}>
              {t('EXCHANGE_KEYS_FORM__descLink')}
            </Link>{' '}
            {t('EXCHANGE_KEYS_FORM__descText')}
          </Desc>
          {Object.entries(values).map(([name, value]) => {
            const helperText =
              (touched[name] && !!errors[name] && errors[name]) || undefined;

            return (
              <Input
                key={name}
                id={`exchange-form-${name}`}
                type={'text'}
                name={name}
                value={value}
                label={t(translations[name].label)}
                placeholder={t(translations[name].placeholder)}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={async () => {
                          const value = await getClipboardText();
                          setFieldValue(name, value);
                        }}
                      >
                        <CopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete={'off'}
                helperText={helperText}
                disabled={isSubmitting}
                error={touched[name] && !!errors[name]}
                onChange={handleChange}
              />
            );
          })}
          <CheckboxWithLabel
            style={{
              marginBottom: 20,
            }}
            name={'accepted-conditions'}
            value={acceptedConditions}
            label={
              <>
                {t(translations.conditions.label)}{' '}
                <Link href={SiteData.TERMS_LINK} {...externalLinkProps}>
                  {t('EXCHANGE_KEYS_FORM__conditionsLabel2')}
                </Link>
              </>
            }
            onChange={(_, checked) => setAcceptedConditions(checked)}
          />
          <SubmitButton
            type={'submit'}
            loading={isSubmitting}
            disabled={!acceptedConditions}
          >
            {t('EXCHANGE_KEYS_FORM__submitButton')}
          </SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

export type ExchangeKeysFormValues = {
  key: string;
  secretKey: string;
};

const Form = styled(DefForm)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Desc = styled(BottomBlockText)`
  margin-bottom: 14px;
`;

const Input = styled(DefInput)`
  .MuiInputBase-adornedEnd {
    padding-right: 0;
  }
`;

const CopyIcon = styled(FileCopyOutlined)`
  font-size: 1.2rem;
  color: ${getColor('blue')};
`;

const SubmitButton = styled(Button)`
  ${bottomButtonStyles};
  margin-top: auto;
`;

const conditionsLinks: {
  [code in ExchangeCode]: string;
} = {
  [ExchangeCode.Kraken]: SiteData.KRAKEN_API_LINK,
  [ExchangeCode.Bitvavo]: SiteData.BITVAVO_TERMS_LINK,
};

const translationsByFieldName: Readonly<{
  [code in ExchangeCode]: {
    [formField in keyof ExchangeKeysFormValues]: {
      label: string;
      placeholder: string;
    };
  } & {
    conditions: {
      label: string;
    };
  };
}> = {
  [ExchangeCode.Kraken]: {
    key: {
      label: 'EXCHANGE_KEYS_FORM__krakenKeyLabel',
      placeholder: 'EXCHANGE_KEYS_FORM__krakenKeyPlaceholder',
    },
    secretKey: {
      label: 'EXCHANGE_KEYS_FORM__krakenSecretKeyLabel',
      placeholder: 'EXCHANGE_KEYS_FORM__krakenSecretKeyPlaceholder',
    },
    conditions: {
      label: 'EXCHANGE_KEYS_FORM__krakenConditionsLabel1',
    },
  },
  [ExchangeCode.Bitvavo]: {
    key: {
      label: 'EXCHANGE_KEYS_FORM__bitvavoKeyLabel',
      placeholder: 'EXCHANGE_KEYS_FORM__bitvavoKeyPlaceholder',
    },
    secretKey: {
      label: 'EXCHANGE_KEYS_FORM__bitvavoSecretKeyLabel',
      placeholder: 'EXCHANGE_KEYS_FORM__bitvavoSecretKeyPlaceholder',
    },
    conditions: {
      label: 'EXCHANGE_KEYS_FORM__bitvavoConditionsLabel1',
    },
  },
};

export { ExchangeKeysForm };
