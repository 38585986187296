/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useURLParams = () => {
  const { search } = useLocation();

  return useMemo(() => {
    try {
      return [...new URLSearchParams(search).entries()];
    } catch (e) {
      return [];
    }
  }, [search]);
};
