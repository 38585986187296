/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AuthLayout as DefAuthLayout } from '../../layouts';
import { SignupForm as DefSignupForm } from '../../components/forms/SignupForm';
import { AuthPageTitle } from '../../components/texts';
import { AuthLogo } from '../../components/AuthLogo';
import { AuthPageChangeBlock } from '../../components/AuthPageChangeBlock';
import { useRegisterReferral, useSignIn } from '../../hooks';
import { GoogleSigninButton } from '../../components/buttons';
import { AuthSplitBlock } from '../../components/AuthSplitBlock';
import { useGoogleSignUpMutation } from '../../apollo';
import { useAlert } from '../../providers';
import { sendSentryError } from '../../utils/sentry';

const SignupPage = () => {
  const { t } = useTranslation();
  const registerReferral = useRegisterReferral();
  const { setAlert } = useAlert();
  const signInHandler = useSignIn();

  const [signup] = useGoogleSignUpMutation({
    fetchPolicy: 'no-cache',
    onCompleted: ({ googleSignUp }) => {
      signInHandler({
        data: googleSignUp,
      });
    },
    onError: (error) => {
      setAlert({
        type: 'error',
        message: t('GOOGLE_AUTH_BUTTON__error'),
      });
      sendSentryError(error);
    },
  });

  return (
    <AuthLayout>
      {registerReferral?.logo && (
        <RefLogo src={registerReferral.logo} alt={'Referral logo'} />
      )}
      <AuthLogo />
      <AuthPageTitle>{t('SIGNUP_PAGE__title')}</AuthPageTitle>
      <GoogleSigninButton
        text={t('GOOGLE_AUTH_BUTTON__signup')}
        onSuccess={(tokenId) => {
          signup({
            variables: {
              tokenId,
            },
          });
        }}
      />
      <AuthSplitBlock />
      <SignupForm referralCode={registerReferral?.company} />
      <AuthPageChangeBlock />
    </AuthLayout>
  );
};

const AuthLayout = styled(DefAuthLayout)`
  position: relative;
`;

const RefLogo = styled.img`
  width: 38px;
  height: 38px;
  position: absolute;
  top: 30px;
  left: 25px;
  object-fit: contain;
`;

const SignupForm = styled(DefSignupForm)``;

export { SignupPage };
