/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

const YoutubeIcon = (props: IconProps) => (
  <Icon viewBox="0 0 32 32" {...props}>
    <path d="M27.672 5.046c-7.286-.49-15.924-.624-23.344 0C2.024 5.304.446 8.108.274 10.238c-.364 4.474-.364 7.02 0 11.494.174 2.13 1.712 4.95 4.056 5.246 7.508.492 16.102.56 23.344 0 2.268-.394 3.882-3.116 4.056-5.246.364-4.474.364-7.298 0-11.774-.176-2.13-1.448-4.816-4.058-4.912zM12 21.66V10.19l10 5.736-10 5.734z" />
  </Icon>
);

export { YoutubeIcon };
