/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useEffect, useRef, useState } from 'react';
import { camelCase, isEmpty } from 'lodash-es';
import { useAlertModal, useURLParams, AlertModalContentType } from 'hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getSavedURLParams,
  removeSavedURLParams,
} from '../utils/saved-url-params';
import { useServerErrorVar } from '../utils/apollo-vars';

const useURLPathNotifications = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const serverError = useServerErrorVar();
  const { dispatch: alertModalDispatch } = useAlertModal();
  const initialLoadRef = useRef(true);
  const [content, setContent] = useState<AlertModalContentType | null>(null);
  const [data, setData] = useState<unknown>(undefined);

  const currentUrlParams = useURLParams();
  const savedURLParams = getSavedURLParams();

  const urlParams = (
    !isEmpty(currentUrlParams)
      ? currentUrlParams
      : !isEmpty(savedURLParams)
      ? savedURLParams
      : []
  ).map(([key, value]) => [camelCase(key), value]);

  useEffect(() => {
    if (serverError || isEmpty([...urlParams])) return;

    for (const [key, value] of urlParams) {
      const content = notificationsData[key]?.[value];
      if (!content) continue;
      const data = urlParams
        .filter(([arrKey]) => arrKey !== key)
        .reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value,
          }),
          {}
        );
      setContent(content);
      setData(!isEmpty(data) ? data : undefined);
      break;
    }
  }, [serverError, urlParams]);

  // Open modal if there is content and it's initial load
  useEffect(() => {
    if (initialLoadRef.current && !!content) {
      initialLoadRef.current = false;
      alertModalDispatch(['setModalContent', content, data]);
      navigate(
        {
          pathname,
          search: '',
        },
        { replace: true }
      );
      removeSavedURLParams();
    }
  }, [alertModalDispatch, pathname, content, data, navigate]);

  return null;
};

// ?exec_rebalance=success
// ?exec_rebalance=error
// ?exec_rebalance=expired
// ?sell_crypto=success
// ?sell_crypto=error
// ?sell_crypto=expired
// ?payment=subscription
// ?payment=single&invoice_id=8
// ?email_change=success
// ?email_change=error
// ?email_change=expired
// ?remove-user=expired

const notificationsData: {
  [key: string]: {
    [key in
      | 'success'
      | 'error'
      | 'expired'
      | 'subscription'
      | 'single']?: AlertModalContentType;
  };
} = {
  execRebalance: {
    success: 'execRebalanceSuccess',
    error: 'rebalanceOrSellError',
    expired: 'rebalanceOrSellExpired',
  },
  sellCrypto: {
    success: 'sellCryptoSuccess',
    error: 'rebalanceOrSellError',
    expired: 'rebalanceOrSellExpired',
  },
  payment: {
    subscription: 'paymentSubscription',
    single: 'paymentSingle',
  },
  emailChange: {
    success: 'changeEmailSuccess',
    error: 'changeEmailError',
    expired: 'changeEmailExpired',
  },
  removeUser: {
    expired: 'removeAccountLinkExpired',
  },
} as const;

export { useURLPathNotifications };
