/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import styled from 'styled-components';
import { Typography } from '@mui/material';

export const DescText = styled(Typography)`
  margin-bottom: 20px;

  ul {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;

    li {
      padding-left: 12px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.palette.primary.main};
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }
`;
