/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React, { forwardRef } from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { isNumber } from 'lodash-es';
import { Button as DefButton, CircularProgress } from '@mui/material';
import { ButtonProps as DefButtonProps } from '@mui/material/Button';
import { green } from '@mui/material/colors';

export type ButtonProps = Omit<DefButtonProps, 'ref'> & {
  className?: string;
  loading?: boolean;
  buttonStyles?: ReturnType<typeof css>;
};

const Button = forwardRef<HTMLDivElement, ButtonProps>(
  (
    {
      children,
      className,
      loading = false,
      disabled,
      buttonStyles,
      ...buttonProps
    },
    ref
  ) => (
    <Wrapper className={className} ref={ref}>
      <StyledButton
        disabled={!!loading || disabled}
        buttonStyles={buttonStyles}
        {...buttonProps}
      >
        {children}
      </StyledButton>
      {loading && <Spinner size={24} />}
    </Wrapper>
  )
);

const Wrapper = styled.div`
  position: relative;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 100%;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const StyledButton = styled(({ buttonStyles, ...rest }) => (
  <DefButton {...rest} />
))<{
  buttonStyles?: FlattenSimpleInterpolation;
}>`
  height: 100%;
  ${({ buttonStyles }) => buttonStyles};
`;

const Spinner = styled(CircularProgress)`
  color: ${green[500]};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: ${({ size }) => (isNumber(size) ? -size / 2 : 0)}px;
  margin-left: ${({ size }) => (isNumber(size) ? -size / 2 : 0)}px;
`;

export { Button };
