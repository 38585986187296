/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */
import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form as DefForm, FormikFormProps } from 'formik';
import { getAdminSendEmailsFormSchema } from '../../utils/validation-schemas';
import { getDownMedia } from '../../styles';
import { Button as DefButton } from '../buttons';
import { FormikInput } from '../formik-elements';

export type AdminSendEmailsFormProps = {
  handleSendAdminEmails: HandleSendAdminEmailsFn;
} & FormikFormProps;

const AdminSendEmailsForm = ({
  handleSendAdminEmails,
  ...props
}: AdminSendEmailsFormProps) => {
  const { t } = useTranslation();
  const schema = useMemo(() => getAdminSendEmailsFormSchema(t), [t]);

  return (
    <Formik<AdminSendEmailsFormValues>
      initialValues={{
        templateId: '',
      }}
      validationSchema={schema}
      onSubmit={({ templateId }) => {
        handleSendAdminEmails(templateId);
      }}
    >
      {() => (
        <Form {...props}>
          <Input
            name={'templateId'}
            label={t('ADMIN_SEND_EMAILS_FORM__templateIDLabel')}
          />
          <Button type={'submit'}>
            {t('ADMIN_SEND_EMAILS_FORM__buttonText')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export type AdminSendEmailsFormValues = {
  templateId: string;
};

export type HandleSendAdminEmailsFn = (templateId: string) => void;

const Form = styled(DefForm)`
  display: flex;
  align-items: flex-start;
  ${getDownMedia('xs')} {
    flex-wrap: wrap;
  }
`;

const Input = styled(FormikInput)`
  margin: 0;
`;

const Button = styled(DefButton)`
  height: 56px;
  margin-left: 10px;

  ${getDownMedia('md')} {
    margin-left: 20px;
  }

  ${getDownMedia('xs')} {
    margin-left: 0;
  }
`;

export { AdminSendEmailsForm };
