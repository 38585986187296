/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import { useURLParams } from './useURLParams';
import beleggersacademy from '../assets/images/ref-logos/beleggersacademy.png';

export const useRegisterReferral = () => {
  const params = useURLParams();
  const company = params.find(([name]) => name === refParam)?.[1];
  return company
    ? {
        company,
        logo: logos[company],
      }
    : null;
};

const refParam = 'utm_source';

const logos: Record<string, string> = {
  beleggersacademy,
};
