/**
 * @licence Copyright © 2019 Mercury Redstone BV, all rights reserved
 */

import React from 'react';
import { Icon, IconProps } from './Icon';

// #3AB047

const EuroIcon = (props: IconProps) => (
  <Icon viewBox="0 0 11 11" {...props}>
    <circle cx="5.5" cy="5.5" r="5.5" fillRule="evenodd" />
    <path
      fill="#FFF"
      fillRule="nonzero"
      d="M3 4.394h.599c.069-.366.178-.697.328-.992.15-.296.333-.547.549-.754a2.34 2.34 0 01.734-.48C5.482 2.057 5.78 2 6.1 2c.418 0 .774.08 1.072.24.297.161.545.378.747.651l-.392.423a.737.737 0 01-.095.088.224.224 0 01-.139.038.196.196 0 01-.115-.036 1.467 1.467 0 01-.114-.09 9.296 9.296 0 00-.14-.117 1.004 1.004 0 00-.182-.117 1.178 1.178 0 00-.25-.09 1.424 1.424 0 00-.342-.036c-.354 0-.652.121-.896.364-.243.244-.412.602-.508 1.076h2.259v.342a.273.273 0 01-.083.191.298.298 0 01-.228.088H4.67a2.567 2.567 0 00-.005.15v.26c0 .036.001.07.005.103h1.907v.338a.273.273 0 01-.083.19.293.293 0 01-.223.088H4.723c.085.517.246.901.484 1.155.239.253.535.38.889.38.216 0 .39-.03.52-.088a1.28 1.28 0 00.32-.196c.082-.072.15-.137.206-.195a.248.248 0 01.182-.088c.034 0 .063.005.088.016.026.01.05.03.075.06l.481.442a2.15 2.15 0 01-.801.744c-.324.173-.7.259-1.13.259-.35 0-.666-.06-.947-.178a2.172 2.172 0 01-.731-.502 2.615 2.615 0 01-.509-.783 4.015 4.015 0 01-.279-1.026H3v-.616h.522a1.144 1.144 0 01-.004-.103v-.26c0-.049.001-.099.004-.15H3v-.621z"
    />
  </Icon>
);

export { EuroIcon };
